/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Button, FormLabel, Grid, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import SingleSelect from "../../SingleSelect";
import { ModalFilePreview } from "../ModalFilePreview";
import CreateNewFile from "../../DataSources/CreateFile/CreateNewFile";
import CreateConnection from "../../DataSources/CreateDS/CreateConnection";
import CustomRadioGroup from "../../CustomComponents/CustomRadioGroup";
// Connection meta data
const databaseMetaData = {
  label: "Select Connection",
  placeholder: "Filter connection list",
};

// Files meta data
const fileMetaData = {
  label: "Select File",
  placeholder: "Filter file list",
};
const SelectDatasource = ({
  heading,
  filesOptions,
  databaseOptions,
  setSourceOption,
  dataSourceType,
  loading,
  setLoading,
  fetchConnectionByType,
  onChangeDataSourceType,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});
  const [addNewConnection, setAddNewConnection] = useState(false);
  const [state, setState] = useState(false);

  const metaDataDetails = dataSourceType === "database" ? databaseMetaData : fileMetaData;
  const dataSourceOptions = dataSourceType === "database" ? databaseOptions : filesOptions;

  const handleDataSource = async (e) => {
    setLoading(true);
    setValue(null);
    setSourceOption(null);
    onChangeDataSourceType(e.target.value);

    if (e.target.value === "file") {
      !filesOptions.length && (await fetchConnectionByType("Files"));
    }

    setLoading(false);
  };

  const fetchConnectionData = async (conType) => {
    setLoading(true);
    try {
      await fetchConnectionByType(conType);
    } catch (e) {
      console.log("Error:", e);
    }
    setLoading(false);
  };

  const onSuccessCreate = (val) => {
    fetchConnectionData("database");
    setValue(val);
    setSelectedOption(val);
    setSourceOption(val);
  };

  const onSuccessFileCreate = (val) => {
    fetchConnectionData("Files");
    setValue(val);
    setSelectedOption(val);
    setSourceOption(val);
  };

  return (
    <>
      <Grid item md={6} sx={{ px: 1 }}>
        <Grid container className="comparisonDS">
          <Grid item xs={12}>
            <CustomRadioGroup
              value={dataSourceType}
              onChange={handleDataSource}
              onClick={() => setSelectedOption({})}
              options={[
                { value: "database", label: t("Database") },
                { value: "file", label: t("File") },
              ]}
              label={`${heading} : `}
            />
          </Grid>
          <Grid item xs={12} className="selectConnection">
            <Grid container sx={{ alignItems: "center" }}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <FormLabel>{t(metaDataDetails.label)}: </FormLabel>
              </Grid>
              <Grid item xs={7}>
                <SingleSelect
                  loader={loading}
                  options={dataSourceOptions}
                  metaData={metaDataDetails}
                  value={value}
                  setValue={setValue}
                  selectedValue={(val) => {
                    setSourceOption(val);
                    setSelectedOption(val);
                  }}
                />
              </Grid>

              <Grid item xs={5}>
                <Box className="v-center" gap={1}>
                  <Button
                    size="small"
                    sx={{ ml: 1 }}
                    variant="contained"
                    onClick={() => (dataSourceType === "file" ? setState(true) : setAddNewConnection(true))}>
                    {t("New")}
                  </Button>

                  {dataSourceType === "file" && <ModalFilePreview selectedOption={selectedOption} heading={heading} />}
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs={12}>
                {selectedOption && Object.keys(selectedOption)?.length > 0 && (
                  <>
                    {dataSourceType === "database" ? (
                      <Typography>
                        <b>{t("Database Type")} : </b>
                        {selectedOption?.connectionType} |{" "}
                        {selectedOption?.server && (
                          <>
                            <b>{t("Server")} :</b> {selectedOption?.server} |{" "}
                          </>
                        )}{" "}
                        {selectedOption?.dataBase && (
                          <>
                            <b>{t("Database")} : </b>
                            {selectedOption?.dataBase} |{" "}
                          </>
                        )}
                        {selectedOption?.serverHostname && (
                          <>
                            <b>{t("Server Host Name")} : </b>
                            {selectedOption?.serverHostname} |{" "}
                          </>
                        )}
                        {selectedOption?.catalogName && (
                          <>
                            <b>{t("Catalog Name")} : </b>
                            {selectedOption?.catalogName} |{" "}
                          </>
                        )}
                        {selectedOption?.accountName && (
                          <>
                            <b>{t("Account Name")} : </b>
                            {selectedOption?.accountName} |{" "}
                          </>
                        )}
                        {selectedOption?.schemas && (
                          <>
                            <b>{t("Schemas")} : </b>
                            {Array.isArray(selectedOption?.schemas)
                              ? selectedOption?.schemas.join(", ")
                              : selectedOption?.schemas}
                          </>
                        )}
                      </Typography>
                    ) : (
                      <Typography>
                        <b>{t("Type")} : </b> {selectedOption?.ext} |<b>{t("Size")} :</b>
                        {(selectedOption?.size * 0.001).toFixed(1)}KB
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {state && <CreateNewFile onSuccessCreate={onSuccessFileCreate} onClose={() => setState(false)} />}

      {addNewConnection && (
        <CreateConnection type="Add" handleSuccessCreate={onSuccessCreate} onClose={() => setAddNewConnection(false)} />
      )}
    </>
  );
};

export default SelectDatasource;
