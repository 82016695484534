import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Grid, Typography, Pagination, IconButton } from "@mui/material";

import ApiService from "../../../services/app.service";
import { formatDateWithTime, totalExecutionsPerPage } from "../../../_helpers/utils";
import SkeletonLoader from "../../SkeletonLoader";
import ResultDetailView from "./ResultDetailView";
import ResultsList from "./ResultsList";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { RESULTS_PER_PAGE } from "../../../_helpers/Constant";
const ExecutionDetailItem = ({ title, value, divider = "" }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ fontWeight: "bold" }}>
      <Typography variant="detailTitle">
        {t(title)} :{" "}
        <Typography variant="detailValue" component="span">
          {value}
        </Typography>
      </Typography>

      {divider && (
        <Divider
          orientation="vertical"
          flexItem
          variant="middle"
          sx={{ borderRightWidth: 2, ml: 2, display: "inline" }}
        />
      )}
    </Box>
  );
};
const Executions = ({ fetchDataValidations }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const currentPage = JSON.parse(searchParams.get("currentPage"));
  const viewType = searchParams.get("viewType");
  const expanded = JSON.parse(searchParams.get("expanded"));
  const ruleId = searchParams.get("ruleId");
  const sortBy = searchParams.get("sortBy");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");

  const [resultValidation, setResultValidation] = useState([]);

  const [selectedExecution, setSelectedExecution] = useState({});

  const [batchesCurrentPage, setBatchesCurrentPage] = useState(1);

  const [executeResultsLoading, setExecuteResultsLoading] = useState(false);

  const [totalExecutionsCount, setTotalExecutionsCount] = useState(0);

  const [executions, setExecutions] = useState([]);

  const [resultsLoading, setResultsLoading] = useState(false);

  const getResultsData = async (activePage, activeSortBy) => {
    setResultsLoading(true);
    try {
      const response = await ApiService.getTestExecutions({
        ruleId: [ruleId],
        sort: activeSortBy || sortBy,
        pageSize: totalExecutionsPerPage,
        page: activePage || currentPage,
        startDate: startDate,
        endDate: endDate,
        isScheduler: false,
      });

      const data = response?.data;
      setExecutions(data?.results);
      setTotalExecutionsCount(data?.recordsCount);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    }
    setResultsLoading(false);
  };

  useEffect(() => {
    if (ruleId) {
      getResultsData();
    }
  }, [ruleId]);

  const getExecutionResults = async (executionId, currentPageNumber) => {
    try {
      setExecuteResultsLoading(true);
      const response = await ApiService.getTestExecutionsResults({
        executionId: executionId,
        pageSize: RESULTS_PER_PAGE,
        page: currentPageNumber,
      });
      setResultValidation(response?.data);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    } finally {
      setExecuteResultsLoading(false);
    }
  };

  const onClickExecutionCard = (obj) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("expanded", true);
      return newState;
    });
    setSelectedExecution(obj);
    getExecutionResults(obj?._id, 1);
  };

  const executionResultsPageChange = (event, page) => {
    if (batchesCurrentPage !== page) {
      setBatchesCurrentPage(page);
      const executionId = selectedExecution?._id;
      getExecutionResults(executionId, page);
    }
  };

  const handlePageChange = (event, page) => {
    if (currentPage !== page) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("currentPage", page);
        return newState;
      });

      try {
        getResultsData(page);
      } catch (error) {
        console.error("Error fetching results:", error);
      }
    }
  };

  const handleSortBy = (sortData) => {
    sortData &&
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("sortBy", sortData);
        return newState;
      });
    getResultsData(currentPage, sortData);
  };

  const handleOnSubmitDateRange = () => {
    getResultsData();
  };

  const handleBack = () => {
    navigate(-2);
    setBatchesCurrentPage(1);
  };

  const renderDetailedView = () => (
    <React.Fragment>
      <Box className="v-center DQRuleExecutionDetails" gap={2}>
        <CustomBackButton onClick={handleBack}>{t("Back")}</CustomBackButton>
        <ExecutionDetailItem divider title="Executed By" value={selectedExecution?.ExecutedBy} />
        <ExecutionDetailItem divider title="Start Time" value={formatDateWithTime(selectedExecution?.startDateTime)} />
        <ExecutionDetailItem divider title="End Time" value={formatDateWithTime(selectedExecution?.finishDateTime)} />
        <ExecutionDetailItem divider title="Total Records" value={selectedExecution?.totalRecordsCount} />
        <ExecutionDetailItem divider title="Batches" value={selectedExecution?.totalBatchCount} />
        <ExecutionDetailItem
          // divider
          title="Checks"
          value={
            selectedExecution?.summary?.FirstDataSourceSelectedColumns?.split(",")?.length
              ? selectedExecution?.summary?.FirstDataSourceSelectedColumns?.split(",")?.length
              : selectedExecution?.Validations?.length
          }
        />
      </Box>

      {executeResultsLoading && <SkeletonLoader />}
      {!executeResultsLoading && (
        <ResultDetailView
          resultValidation={resultValidation}
          batchesCurrentPage={batchesCurrentPage}
          selectedExecution={selectedExecution}
          executionResultsPageChange={executionResultsPageChange}
        />
      )}
    </React.Fragment>
  );

  const renderResultList = () => (
    <Box>
      {executions.length > 0 && (
        <Grid position="relative" container className="DQRuleExecutionDetails">
          <Grid item sm={4}>
            <Typography variant="detailTitle">
              {t("DQ Rule Name")} :{" "}
              <Typography component="span" variant="detailValue">
                {executions[0]?.validationDetails?.TestName}
              </Typography>
            </Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="detailTitle">
              {t("Rule Type")} :{" "}
              <Typography component="span" variant="detailValue">
                {executions[0]?.validationDetails?.TestType}
              </Typography>
            </Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="detailTitle">
              {t("Number of Executions")} :{" "}
              <Typography component="span" variant="detailValue">
                {totalExecutionsCount}
              </Typography>
            </Typography>
          </Grid>
          <IconButton
            onClick={() => setSearchParams({})}
            sx={{ position: "absolute", top: 0, right: 0 }}
            size="small"
            color="error">
            <CancelIcon />
          </IconButton>
        </Grid>
      )}
      <ResultsList
        resultsLoading={resultsLoading}
        executions={executions}
        setExecutions={setExecutions}
        handleSortBy={handleSortBy}
        onClickExecutionCard={onClickExecutionCard}
        handleOnSubmitDateRange={handleOnSubmitDateRange}
        fetchDataValidations={fetchDataValidations}
      />

      {viewType === "grid" && (
        <Pagination
          sx={{ my: 1.5, marginLeft: "auto", float: "right" }}
          count={Math.ceil(totalExecutionsCount / totalExecutionsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );

  return <Box className="DQRulesResultsContainer">{expanded ? renderDetailedView() : renderResultList()}</Box>;
};

export default Executions;
