import { Box } from "@mui/material";
import { useState } from "react";
import ComparativeValidatePreview from "./ComparativeValidatePreview";
import FailedResult from "./FailedResult";
import CustomDialog from "../CustomComponents/CustomDialog";

const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: "80%",
  bgcolor: "background.paper",
  // borderTop: "10px solid #2b81d6",
  // boxShadow: 24,
  // px: 2,
  // pb: 2,
  // borderRadius: "4px",
  maxHeight: "97vh",
};

export default function ValidateResultModal(props) {
  const { Validations, Comparative = false, model, returnValue, validationDetailsRowComparison } = props;

  const [open, setOpen] = useState(model);

  const handleClose = () => {
    setOpen(false);
    returnValue(false);
  };
  const autoScroll = () => null;

  return (
    <CustomDialog open={open} title="Data Quality Rule Execution Result" onClose={handleClose} fullWidth={true}>
      <Box sx={style}>
        {Validations && Comparative ? (
          <ComparativeValidatePreview
            resultValidation={Validations}
            validationDetailsRowComparison={validationDetailsRowComparison}
          />
        ) : (
          <FailedResult autoScroll={autoScroll} validations={Validations} validatemodal={model} />
        )}
      </Box>
    </CustomDialog>
  );
}
