/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { InputBase, Paper, IconButton, Typography, Box } from "@mui/material";
import { Search } from "@mui/icons-material";
import APIServices from "../../services/app.service";
import { SnackbarContext } from "../../App";
import SkeletonLoader from "../SkeletonLoader";
import CustomSelect from "../CustomComponents/CustomSelect";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import CreateNewFile from "./CreateFile/CreateNewFile";
import CreateConnection from "./CreateDS/CreateConnection";
import InnerHeader from "../Layout/InnerHeader";
import DataSourceDetails from "./DataSourceDetails";
import CustomToggleButton from "../CustomComponents/CustomsButtons/CustomToggleButton";
import CustomBackButton from "../CustomComponents/CustomsButtons/CustomBackButton";
// import DataProfileStatusIndicator from "./DataProfileStatusIndicator";
import CustomAddButton from "../CustomComponents/CustomsButtons/CustomAddButton";
import DataSourcesList from "./DataSourcesList";

const DataSourceInnerComponent = ({
  sources,
  searchInput,
  onClickConnectionDb,
  setOpenFileData,
  onClickAddConnection,
  handleSearch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const DSType = searchParams.get("type");
  const DSName = searchParams.get("name");
  const viewType = searchParams.get("view");
  const { checkAccess } = useContext(UserDetailsContext);
  const selectedOption = sources.find((source) => source?.name === DSName);

  const handleBackButtonClick = () => navigate("/DataSource");

  return (
    <InnerHeader>
      <Box className="dataSourceInnerHead">
        <Box className="dataSourceTypesHead" width="100%">
          <Box sx={{ gap: 2 }} className="v-center">
            {DSName && <CustomBackButton onClick={handleBackButtonClick}>{t("Back")}</CustomBackButton>}
            <Typography variant="h6" className="upper-case">
              {t("Data Sources")}
            </Typography>

            <Box className="v-center" gap={2} ml="auto">
              {/* <DataProfileStatusIndicator /> */}
              {DSName && sources?.length > 0 && (
                <>
                  <CustomSelect
                    options={sources}
                    value={selectedOption}
                    onChange={(e) => onClickConnectionDb(e.target.value)}
                    renderOptionIcon={(option) => <img width={25} src={option.imageUrl} alt="" />}
                    renderOptionText={(option) => `${t(option.name)} - ${option.count}`}
                  />
                  <CustomToggleButton
                    sx={{ display: "none" }}
                    viewType={viewType || "grid"}
                    onChange={(event, newVal) => {
                      setSearchParams((prev) => {
                        const newState = new URLSearchParams(prev);
                        newState.set("view", newVal);
                        return newState;
                      });
                    }}
                  />
                  {checkAccess("DataSource", "Create") && (
                    <CustomAddButton
                      onClick={() => {
                        DSType === "Files" ? setOpenFileData(selectedOption) : onClickAddConnection(selectedOption);
                      }}
                      label={t("Add new")}
                    />
                  )}
                </>
              )}
              {!DSName && (
                <>
                  <Paper className="v-center" sx={{ p: "2px 4px" }}>
                    <InputBase
                      size="small"
                      className="searchInput"
                      placeholder={t("search datasources here")}
                      value={searchInput}
                      onChange={handleSearch}
                    />

                    <IconButton sx={{ p: 0 }} size="small">
                      <Search fontSize="0.75rem" />
                    </IconButton>
                  </Paper>
                  <CustomToggleButton
                    viewType={viewType || "grid"}
                    onChange={(event, newVal) => newVal && setSearchParams({ view: newVal })}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </InnerHeader>
  );
};

DataSourceInnerComponent.propTypes = {
  sources: PropTypes.array.isRequired,
  searchInput: PropTypes.string.isRequired,
  onClickConnectionDb: PropTypes.func.isRequired,
  setOpenFileData: PropTypes.func.isRequired,
  onClickAddConnection: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

const DataSources = () => {
  const gridRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const DSType = searchParams.get("type");
  const [dataSourcesLoading, setDataSourcesLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [dataSourcesList, setDataSourcesList] = useState([]);
  const [openFileData, setOpenFileData] = useState({});
  const [addConnectionData, setAddConnectionData] = useState("");
  const [loadingConnectionDetails, setLoadingConnectionDetails] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const onClickAddConnection = (conData) => {
    setAddConnectionData(conData);
  };

  const refreshData = (isEdit) => {
    if (gridRef.current) {
      gridRef.current.api.refreshServerSide({ purge: true });
    }
    !isEdit && getDataSources();
  };

  const handleSuccessCreate = (newData) => {
    setDataSourcesList((prevState) =>
      prevState?.map((source) => {
        if (source?.name?.toLowerCase() === newData?.connectionType?.toLowerCase()) {
          return { ...source, count: source?.count + 1 };
        }
        return source;
      }),
    );
    if (newData.connectionType.toLowerCase() === "xls") {
      newData = { ...newData, connectionType: "XLSX" };
    }
    const DSDetails = dataSourcesList?.find(
      (each) => each?.name?.toLowerCase() === newData?.connectionType?.toLowerCase(),
    );
    if (DSType) {
      refreshData();
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        DSDetails?.type !== "Files" && newState.set("dataProfileStatus", true);
        return newState;
      });
    } else {
      setSearchParams({
        type: DSDetails?.type,
        name: DSDetails?.name,
        ...(DSDetails?.type !== "Files" &&
          ["My SQL", "SQL", "PostgreSQL", "Snowflake", "Azure SQL", "SAP HANA", undefined].includes(
            DSDetails?.type,
          ) && { dataProfileStatus: true }),
      });
    }
  };

  const onClickConnectionDb = async (connectionDbObj) => {
    if (connectionDbObj?.enable) {
      setLoadingConnectionDetails(true);
      setSearchParams({ type: connectionDbObj?.type, name: connectionDbObj?.name });
      setTimeout(() => {
        setLoadingConnectionDetails(false);
      }, 1000);
    } else {
      setSnack({ message: "Not available in the version", open: true, colour: "warning" });
    }
  };

  const getDataSources = async () => {
    setDataSourcesLoading(true);
    try {
      const response = await APIServices.dataSources({ key: "" });
      setDataSourcesList(response?.data?.sources);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setDataSourcesLoading(false);
    }
  };

  useEffect(() => {
    getDataSources();
  }, []);

  const filteredSources = dataSourcesList?.filter((source) =>
    source?.name?.toLowerCase()?.includes(searchInput?.toLowerCase()),
  );

  return (
    <>
      <DataSourceInnerComponent
        sources={dataSourcesList}
        searchInput={searchInput}
        setOpenFileData={setOpenFileData}
        handleSearch={handleSearch}
        onClickConnectionDb={onClickConnectionDb}
        onClickAddConnection={onClickAddConnection}
      />

      <Box className="pt74">
        {dataSourcesLoading || loadingConnectionDetails ? (
          <SkeletonLoader />
        ) : DSType ? (
          <DataSourceDetails gridRef={gridRef} refreshData={refreshData} />
        ) : (
          <DataSourcesList
            setOpenFileData={setOpenFileData}
            sources={filteredSources}
            onClickAddConnection={onClickAddConnection}
            onClickConnectionDb={onClickConnectionDb}
          />
        )}
      </Box>
      {openFileData?.name && (
        <CreateNewFile
          fileType={openFileData?.name}
          onClose={() => setOpenFileData({})}
          onSuccessCreate={handleSuccessCreate}
        />
      )}

      {addConnectionData?.name && (
        <CreateConnection
          type="Add"
          defaultConType={addConnectionData?.name}
          handleSuccessCreate={handleSuccessCreate}
          onClose={() => setAddConnectionData("")}
        />
      )}
    </>
  );
};

export default DataSources;
