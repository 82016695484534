import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Box, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import { mdiFilterRemove } from "@mdi/js";
import Icon from "@mdi/react";

const CustomHeaderAgGrid = ({ data, highLightColumn, errorColumn }) => {
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  // const [pageSize, setPageSize] = useState(10);
  const [resetStatus, setResetStatus] = useState(true);

  data = useMemo(
    () =>
      data.map((obj) => Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.replace(/\./g, ""), value]))),
    [data],
  );
  const gridOptions = useMemo(() => {
    const paginationPageSizeOptions = [10, 25, 50, 100];
    return {
      defaultColDef: { sortable: true, filter: "agTextColumnFilter", resizable: true, minWidth: 60 },
      columnDefs: [],
      suppressCellFocus: true,
      suppressBrowserResizeObserver: true,
      paginationPageSize: 10,
      pagination: true,
      getMainMenuItems: (params) => {
        const defaultItems = params.defaultItems;
        return defaultItems.filter((item) => item !== "resetColumns");
      },
      paginationPageSizeSelector: paginationPageSizeOptions,
    };
  }, []);

  const clearFilters = useCallback(() => {
    if (gridRef.current) {
      const api = gridRef.current.api;
      api.setFilterModel(null);
      const columnDefs = api.getColumnDefs();
      columnDefs.forEach((colDef) => {
        api.setColumnVisible(colDef.field, true);
      });
      api.deselectAll();
      setResetStatus(true);
    }
  }, [gridRef]);

  const onFilterChanged = useCallback(() => {
    const filterModel = gridRef?.current?.api?.getFilterModel();
    const dataKeys = Object.keys(filterModel)?.length === 0;

    setResetStatus(dataKeys);
    const api = gridRef?.current?.api;
    api?.hideOverlay();
    api?.deselectAll();
    if (api?.rowModel?.rowsToDisplay?.length === 0) {
      api?.showNoRowsOverlay();
    }
  }, [gridRef]);

  const onColumnVisible = useCallback(() => {
    const api = gridRef.current?.api;
    const visibleColumns = api?.getAllDisplayedColumns();
    const allColumns = api?.getColumnDefs();
    const noColumnsVisible = visibleColumns.length === allColumns?.length;

    setResetStatus(noColumnsVisible);
  }, []);

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.sizeColumnsToFit();
  }, [gridRef]);

  const onPaginationChanged = useCallback((e) => {
    e.api.deselectAll();
  }, []);

  const onGridSizeChanged = useCallback((params) => {
    params?.api?.sizeColumnsToFit();
  }, []);

  const onGridReady = useCallback(
    (params) => {
      gridRef?.current?.api?.sizeColumnsToFit();
      setGridApi(params.api);
    },
    [gridRef],
  );

  useEffect(() => {
    if (gridApi) {
      if (!data || data.length === 0) {
        return;
      }

      const colDefs = [];
      const keys = Object.keys(data[0]);
      keys.forEach((key) => {
        const isErrorColumnIncludes =
          typeof errorColumn?.columns === "string"
            ? errorColumn?.columns?.replace(/\./g, "") === key
            : errorColumn?.columns?.includes(key);

        const isHighLightColumnIncludes =
          typeof highLightColumn?.columns === "string"
            ? highLightColumn?.columns === key
            : highLightColumn?.columns.includes(key);

        if (isHighLightColumnIncludes) {
          const color = highLightColumn.color;
          colDefs.push({
            field: key,
            cellStyle: { color },
            width: 500,
            headerComponent: (params) => (
              <span style={{ color }}>
                <b>{params.displayName}</b>
              </span>
            ),
          });
        } else if (isErrorColumnIncludes) {
          const color = errorColumn?.color;
          colDefs.push({
            field: key,
            cellStyle: { color },
            width: 500,
            headerName: key,
            headerClass: "errorColumnClass",
          });
        } else {
          colDefs.push({ field: key });
        }
      });

      gridApi.setGridOption("columnDefs", colDefs);
    }
  }, [gridApi, data, errorColumn, highLightColumn]);

  return (
    <React.Fragment>
      <Box className="refreshBtn">
        <IconButton color="error" size="small" variant="outlined" onClick={clearFilters} disabled={resetStatus}>
          <Tooltip title="Clear Filters" placement="right">
            <Icon path={mdiFilterRemove} size={0.5} />
          </Tooltip>
        </IconButton>
      </Box>
      <Grid container>
        <Grid sm={12} item>
          <Box className="ag-theme-balham noBorderRadiusAggrid">
            <Box position="relative">
              <Paper>
                <AgGridReact
                  ref={gridRef}
                  rowData={data}
                  columnDefs={gridOptions.columnDefs}
                  animateRows={true}
                  onFilterChanged={onFilterChanged}
                  onColumnVisible={onColumnVisible}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  gridOptions={gridOptions}
                  paginationPageSize={10}
                  pagination={true}
                  onFirstDataRendered={onFirstDataRendered}
                  onPaginationChanged={onPaginationChanged}
                  onGridSizeChanged={onGridSizeChanged}
                  onGridReady={onGridReady}
                />
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomHeaderAgGrid;
