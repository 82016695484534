/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";

import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import io from "socket.io-client";
import {
  AccessTime as AccessTimeIcon,
  Delete as DeleteIcon,
  NotStartedSharp as NotStartedSharpIcon,
  OutputRounded as OutputRoundedIcon,
  Input as InputIcon,
} from "@mui/icons-material";
import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import InnerHeader from "../Layout/InnerHeader";
// import PipeLinePopUpScheduler from "../PipeLines/PipeLine";
import { dateRangeObj } from "../../_helpers/utils";
import CreateScheduledTest from "../TestScheduler/CreateScheduledTest/CreateScheduledTest";
import Executions from "./Executions";
import ExecutionsView from "./Executions/ViewExecutions/ExecutionsView";
import PreviewValidationData from "./PreviewValidationData";
import CreateNewDqRule from "./Step2/CreateNewDqRule";
import { headCellss } from "./TestHubModels";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import AgGridSSRM from "../AgGrid/AgGridSSRM";
import {
  CustomEditIcon,
  CustomExecuteIcon,
  CustomPreviewIcon,
  CustomResultIcon,
} from "../CustomComponents/IconButtons";
import CustomAddButton from "../CustomComponents/CustomsButtons/CustomAddButton";
import { isAzure } from "../../_helpers/Constant";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";

const EnhancedTableToolbar = ({
  // selectedRowId,
  numSelected,
  selectedRows,
  handleClickOpenDialog,
  fetchDataValidations,
  clearPreview,
  gridRef,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const { userDetails, checkAccess } = useContext(UserDetailsContext);
  const [loading, setLoading] = useState(false);
  const [schduleTestOpen, setSchduleTestOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const isAdmin = userDetails?.RoleName === "Admin";

  const displayedRowsData = () => {
    if (gridRef?.current) {
      const displayedRowsData = [];

      // Iterate over all nodes, including those not currently displayed
      gridRef.current.api.forEachNode((node) => {
        displayedRowsData.push(node.data);
      });
      setRows(displayedRowsData);
    }
  };

  useEffect(() => {
    displayedRowsData();
  }, [gridRef, selectedRows]);

  const userCreatedRules = rows.filter((each) => each?.UserId !== userDetails?.UserId);
  const schduledOrCollectionRules = rows?.filter((each) => each?.isScheduled || each?.isInCollection);
  const isSelectedScheduled = schduledOrCollectionRules.some((each) => selectedRows.includes(each?._id));

  const isDiableDelete = userCreatedRules.some((each) => selectedRows.includes(each?._id)) && !isAdmin;

  const updateRowSelectability = () => {
    if (!gridRef?.current) {
      return;
    }
    const selectedIdsSet = new Set(selectedRows);
    gridRef?.current?.api?.forEachNode((node) => {
      const nodeId = node?.data?._id;
      if (selectedIdsSet.has(nodeId)) {
        node.setRowSelectable(false);
        node.data.isExecutionRunning = 1;
      }
    });
  };

  const handleMultipleExecution = async () => {
    try {
      updateRowSelectability();

      clearPreview();
      setLoading(true);
      gridRef?.current?.api?.deselectAll();
      setSnack({
        message: "DQ Rule Execution Started",
        open: true,
        colour: "success",
      });
      const payload = {
        ruleIds: selectedRows,
      };
      await ApiService.executeMultipleTest(payload);
      setSnack({
        message: "DQ Rule Execution Completed",
        open: true,
        colour: "success",
      });
      fetchDataValidations();
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onSuccessCreateScheduled = () => {
    setSchduleTestOpen(false);
    navigate("/ScheduledTest/list");
  };

  return (
    <Toolbar
      sx={{
        minHeight: { xs: 35 },
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        py: { xs: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      {numSelected > 0 && (
        <React.Fragment>
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
            {numSelected} {t("Selected")}
          </Typography>
          {checkAccess("DataQualityRule", "Delete") && (
            <Tooltip
              arrow
              placement="top-end"
              title={t(
                isDiableDelete
                  ? "Can't delete this rule. Not created by you."
                  : isSelectedScheduled
                  ? "Please deselect rules that are in collection or scheduled."
                  : "Delete",
              )}>
              <Box>
                <IconButton
                  disabled={isDiableDelete || isSelectedScheduled}
                  size="small"
                  onClick={handleClickOpenDialog}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}
          {checkAccess("ScheduledTest", "Create") && (
            <Tooltip
              placement="top-end"
              arrow
              title={t(isDiableDelete ? "Kindly deselect rules that you did not create." : "Schedule")}>
              <Box>
                <IconButton
                  disabled={isDiableDelete}
                  onClick={() => setSchduleTestOpen(true)}
                  size="small"
                  variant="outlined">
                  <AccessTimeIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}
          {checkAccess("DataQualityRule", "Execute") && numSelected <= 5 && (
            <Tooltip arrow placement="top-end" title={t("Execute Selected")} disabled={loading}>
              <IconButton size="small" onClick={handleMultipleExecution}>
                <NotStartedSharpIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* <PipeLinePopUpScheduler
            dropDownData={rows}
            icon={<AllInclusiveOutlinedIcon />}
            selected={selectedRows}
            type={"icon"}
          /> */}

          {schduleTestOpen && (
            <CreateScheduledTest
              selectedRules={selectedRows}
              setSchduleTestOpen={setSchduleTestOpen}
              onSuccessCreateScheduled={onSuccessCreateScheduled}
            />
          )}
        </React.Fragment>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes?.number?.isRequired,
};

function ActionsCell(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDetails, checkAccess } = useContext(UserDetailsContext);
  const [loading, setLoading] = useState(false);
  const [exportJson, setExportJson] = useState(false);
  const row = props?.data;

  const handleExecuteClick = () => {
    props?.node?.setRowSelectable(false);
    props?.context?.executeTest(row?._id, props?.node, setLoading);
  };

  const handleEditClick = (path) => {
    navigate(path);
  };

  const showEdit =
    props?.data?.UserId === userDetails?.UserId || userDetails?.RoleName === "Admin" ? "visible" : "hidden";
  const isDQRuleExecutionRunning = props?.data?.isExecutionRunning > 0;
  const reset = true;
  return (
    <Box className="v-center">
      {row?.TestType === "Comparison" && (
        <CustomEditIcon
          sx={{ visibility: showEdit }}
          title={t("Edit")}
          onClick={() =>
            handleEditClick(
              `/DataQualityRule/row-comparison/create?ruleId=${row._id}&firstSourceConId=${row?.FirstDatasourceId}&secondSourceConId=${row.SecondDatasourceId}`,
            )
          }
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}

      {(row?.TestType === "Single File" || row?.TestType === "Single API") && (
        <CustomEditIcon
          sx={{ visibility: showEdit }}
          title={t("Edit")}
          onClick={() =>
            handleEditClick(
              `/DataQualityRule/create?connectionId=${row?.ConnectionId}&ruleId=${row._id}&ruleName=${
                row?.TestName
              }&testType=${row?.TestType === "Single Database" || isAzure(row?.ConnectionType) ? "database" : "Files"}`,
            )
          }
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}
      {row?.TestType === "Single Database" && (
        <CustomEditIcon
          sx={{ visibility: showEdit }}
          title={t("Edit")}
          onClick={() =>
            handleEditClick(
              `/DataQualityRule/create?connectionId=${row?.ConnectionId}&ruleId=${row._id}&ruleName=${
                row?.TestName
              }&testType=${row?.TestType === "Single Database" ? "database" : "Files"}`,
            )
          }
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}
      {checkAccess("DataQualityRule", "Preview") && (
        <CustomPreviewIcon title={t("Preview")} onClick={() => props.context.ClickPreviewConnection(row)} />
      )}
      {checkAccess("DataQualityRule", "Execute") && (
        <CustomExecuteIcon
          title={t("Execute")}
          onClick={handleExecuteClick}
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}
      <CustomResultIcon title={t("Results")} onClick={() => props.context.handleOnClickResults(row?._id, reset)} />
      <IconButton
        // title={t("Export Rule")}
        className="v-center"
        aria-label={t("Export Rule")}
        onClick={() => props.context.handleExportDQRule(row?._id, setExportJson)}
        size="small"
        disabled={exportJson}
        sx={{ color: "#123e8a" }}>
        <Tooltip className="v-center" title={t("Export Rule")} placement="top" arrow>
          <OutputRoundedIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
}
export default function DataValidationsList() {
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const scrollRef = useRef();
  const { checkAccess } = useContext(UserDetailsContext);

  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: "DESC",
    currentPage: 1,
    startDate: dateRangeObj.startDate,
    endDate: dateRangeObj.endDate,
  });

  const ruleId = searchParams.get("ruleId");
  const previewId = searchParams.get("previewId");
  const createRule = JSON.parse(searchParams.get("createRule"));
  const [selected, setSelected] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 600);
  };

  const refreshData = useCallback(() => {
    if (gridRef) {
      gridRef?.current?.api?.refreshServerSide({ purge: true });
    }
  }, [gridRef]);

  const deleteFiles = async () => {
    setLoadingDelete(true);
    try {
      await ApiService.ValidationTestDelete({ ids: selected });
      setSelected([]);
      setOpenDialog(false);
      setSnack({ message: "Successfully deleted", open: true, colour: "success" });
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
    } finally {
      setLoadingDelete(false);
      refreshData();
    }
  };

  const executeTest = async (row, rowNode, setLoading) => {
    setSearchParams({});
    setLoading(true);
    gridRef?.current?.api?.deselectAll();

    setSnack({ message: "DQ Rule Execution Started", open: true, colour: "success" });
    try {
      const response = await ApiService.ExcuteTestValidation(row);
      const formattedDate = moment.utc(response?.data?.DateTime).local().format("MM/DD/YYYY h:mm:ss A");
      rowNode.setDataValue("LastExecution", formattedDate);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    } finally {
      setLoading(false);
      rowNode.setRowSelectable(true);
    }
  };

  const ClickPreviewConnection = (row) => {
    gridRef.current.api.deselectAll();
    setSelected([]);
    setSearchParams({ previewId: row._id });
    autoScroll();
  };

  const handleOnClickResults = (rowId) => {
    setSearchParams({
      ruleId: rowId,
      sortBy: "DESC",
      currentPage: 1,
      viewType: "grid",
      startDate: dateRangeObj.startDate,
      endDate: dateRangeObj.endDate,
    });

    autoScroll();
  };

  const handleExportDQRule = async (rowId, setExportJson) => {
    setExportJson(true);
    gridRef?.current?.api?.deselectAll();
    try {
      const response = await ApiService.exportRule(rowId);
      const Json = response?.data;
      delete Json?._id;
      if (Json) {
        const jsonData = JSON.stringify(Json, null, 2);

        const blob = new Blob([jsonData], { type: "application/json" });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${Json?.TestName}.json`;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);
      }
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    } finally {
      setExportJson(false);
    }
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  };

  headCellss[headCellss.length - 1] = action;

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between ">
          <Box className="v-center">
            <Typography variant="h6" className="upper-case">{`${t("Data Quality Rules")}`}</Typography>
          </Box>
          <Box>
            {checkAccess("DataQualityRule", "Create") && (
              <CustomAddButton
                sx={{ marginRight: "8px" }}
                startIcon={<InputIcon />}
                onClick={() => setSearchParams({ importRule: true })}
                label={t("Import Rule")}
              />
            )}

            {checkAccess("DataQualityRule", "Create") && (
              <CustomAddButton
                onClick={() => setSearchParams({ createRule: !createRule })}
                label={t("Create DQ Rule")}
              />
            )}
          </Box>
        </Box>
      </InnerHeader>
      <Box className="pt74" ref={scrollRef}>
        <Box sx={{ width: "100%", mb: 2 }}>
          <CreateNewDqRule refreshData={refreshData} />
          <Box className="createBtn">
            {selected?.length !== 0 && (
              <EnhancedTableToolbar
                selectedRowId={selectedRowId}
                selectedRows={selected}
                numSelected={selected?.length}
                handleClickOpenDialog={handleClickOpenDialog}
                fetchDataValidations={refreshData}
                clearPreview={() => setSelected([])}
                gridRef={gridRef}
              />
            )}
          </Box>
          <AgGridSSRM
            gridRef={gridRef}
            setSelected={setSelected}
            setSelectedRowId={setSelectedRowId}
            apiMethod={"GettestHubDataValidations"}
            headCells={headCellss}
            context={{
              ClickPreviewConnection: ClickPreviewConnection,
              handleOnClickResults: handleOnClickResults,
              handleExportDQRule: handleExportDQRule,
              executeTest: executeTest,
            }}
          />
        </Box>

        {previewId && (
          <PreviewValidationData autoScroll={autoScroll} connection={previewId} returnVal={() => setSearchParams({})} />
        )}

        {ruleId && <Executions fetchDataValidations={refreshData} />}
        <ExecutionsView fetchDataValidations={refreshData} />
      </Box>

      {openDialog && (
        <CustomDeleteModal
          loading={loadingDelete}
          disabled={loadingDelete}
          onClickDelete={deleteFiles}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}
