import { Box } from "@mui/material";
// import { useState, useMemo } from "react";
import CustomHeaderAgGrid from "../AgGrid/CustomHeaderAgGrid";
// import { useTranslation } from "react-i18next";
// const defaultTypes = {
//   MatchedFirstDatasourceCount: "MatchedInFirstSource",
//   MatchedSecondDatasourceCount: "MatchedInSecondSource",
// };
export default function MatchedTable(props) {
  const { headCells, highLightColumn, type } = props;
  let highLightColumnNames = type === "MatchedInFirstSource" ? highLightColumn.columnOne : highLightColumn.columnTwo;
  return (
    <Box sx={{ mb: 2, textAlign: "center", pb: 1 }}>
      <CustomHeaderAgGrid data={headCells} errorColumn={{ columns: highLightColumnNames, color: "#bd0940" }} />
    </Box>
  );
}
