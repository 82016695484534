import { Drawer } from "@mui/material";
import { Box, Button, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import { AuthenticationTypes, connectionTypes } from "../../../_helpers/Constant";
import ApiService from "../../../services/app.service";
import CustomSwitchButton from "../../CustomComponents/CustomSwitchButton/index.js";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import AwsS3 from "./AWS_S3";
import AuthenticationSection from "./AuthenticationSection";
import AzureBlob from "./AzureBlob";
import BigQueryForm from "./BigQueryForm";
import DatabricksForm from "./Databricks.js";
import DropBox from "./DropBox.js";
import Generic from "./Generic";
import HBaseForm from "./HBaseForm";
import MongoDB from "./MongoDB";
import OneDrive from "./OneDrive.js";
import OracleForm from "./OracleForm";
import SalesforceForm from "./SalesforceForm";
import SelectSchema from "./SelectSchema.js";
import ViewConnectionDetails from "./ViewConnectionDetails";
import { useDispatch, useSelector } from "react-redux";
import { addConnection, addConnectionsList } from "../../../Redux/reducers/connectionSlice";
import APIServices from "../../../services/app.service";
import { showToast } from "../../../Redux/reducers/toastSlice.js";

const CreateConnection = ({
  type = "Add",
  connectionData = {},
  handleSuccessCreate,
  defaultConType = null,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const connectionsInfo = useSelector((state) => state.connectionData);
  const [viewType, setViewType] = useState(type);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [schemaLoading, setSchemaLoading] = useState(false);
  const [requiredFieldsChanges, setRequiredFieldsChanges] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [expresponse, setExpresponse] = useState(connectionData?.ValidateWithExpectedResponse === true);

  const [filteredAuthTypes, setFilteredAuthTypes] = useState(
    AuthenticationTypes?.filter((each) => each.types.includes(defaultConType || null)),
  );

  const { _id, id: connectionId, ...connectionDataWithoutId } = connectionData || {};
  const defaultSchema = connectionData?.schema || connectionData?.schemas;

  console.log(_id, "_id");

  const defaultValues = {
    ...connectionDataWithoutId,
    connectionType: defaultConType || null,
    authenticationType: connectionData?.authenticationType || filteredAuthTypes[0]?.value,
    schemas: defaultSchema,
    isPrivate: connectionId ? connectionData?.isPrivate || false : true,
    serviceType: connectionData?.serviceType || "sid",
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
    trigger,
  } = useForm({ defaultValues });

  const conType = getValues()?.connectionType;

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleDrawerClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const isUsedInTests = connectionData?.UsedInTests?.length > 0;
  const addButton = isUsedInTests ? t("Create As New") : viewType === "Add" ? t("Create") : t("Update");

  const onSubmitTestCreate = async (data) => {
    setLoadingSubmit(true);
    try {
      let response;
      if (connectionId && !isUsedInTests) {
        response = await ApiService.ConnectionUpdate(data, connectionId);
      } else {
        response = await ApiService.ConnectionCreate(data);
        const connectionList = connectionsInfo?.connections?.database;
        if (connectionList) {
          dispatch(addConnection({ type: "database", connection: response?.data?.row }));
        } else {
          const response = await APIServices.getConnectionsByType({ type: "database" });
          dispatch(addConnectionsList({ type: "database", connectionsList: response?.data }));
        }
      }
      await handleSuccessCreate(response?.data?.row);
      setSnack({ message: response?.data?.message, open: true, colour: "success" });
      handleDrawerClose();
    } catch (error) {
      const errorMessage =
        error?.response?.status === 422
          ? error?.response?.data?.connectionName?.message
          : error?.response?.data?.message || error.message;

      dispatch(showToast({ message: errorMessage, type: "error" }));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onSubmitValidate = async (data) => {
    setLoadingValidate(true);
    try {
      await ApiService.TestConnection(data);
      setSnack({ message: "Connected successfully", open: true, colour: "success" });
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
    } finally {
      setLoadingValidate(false);
    }
  };

  // const checkValidateUnique = async (val) => {
  //   const checkVal = await ApiService.CheckConnectionUnique({
  //     key: val,
  //     id: connectionId,
  //   });
  //   if (checkVal.data.data > 0) {
  //     return "Data Source Name already existed";
  //   } else {
  //     return true;
  //   }
  // };

  const handleChangeInput = (e, field) => {
    const cleanedValue = e.target.value.replace(/\s{2,}/g, " ");
    field.onChange(cleanedValue);
    if (!requiredFieldsChanges) {
      setRequiredFieldsChanges(true);
      clearErrors();
    }
  };

  const handleChangeConnectionType = (event, field) => {
    const { value } = event.target;
    field.onChange(value);
    const newAuthTypes = AuthenticationTypes?.filter((each) => each.types.includes(value));
    setValue("authenticationType", newAuthTypes[0]?.value || null);
    setFilteredAuthTypes(newAuthTypes);
  };

  return (
    <Drawer disableEnforceFocus disableAutoFocus anchor={"right"} open={isOpen} onClose={handleDrawerClose}>
      <Box sx={{ width: 500 }}>
        <Typography sx={{ px: 2, py: 1 }} variant="h6">
          {t(viewType)} {t(conType)} {t("Data Source")}
        </Typography>
        <Divider />

        {viewType === "View" ? (
          <ViewConnectionDetails
            connectionData={connectionData}
            onClose={handleDrawerClose}
            setViewType={setViewType}
          />
        ) : (
          <Box component="form" noValidate autoComplete="off">
            <Grid sx={{ p: 2 }} item container gap={2}>
              <Grid item sm={12}>
                <Controller
                  name="connectionName"
                  control={control}
                  rules={{
                    required: { value: true, message: "This field is required" },
                    minLength: { value: 4, message: "Minimum length is 4 characters" },
                    maxLength: { value: 32, message: "Maximum length is 32 characters" },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={Boolean(errors.connectionName)}
                      helperText={errors?.connectionName?.message || null}
                      label={t("Data Source Name")}
                      variant="outlined"
                      required
                      size="small"
                      onChange={(e) => {
                        const inputValue = e.target.value.replace(/\s{2,}/g, " ");
                        field.onChange(inputValue);
                        trigger("connectionName");
                      }}
                    />
                  )}
                />
              </Grid>
              {!defaultConType && (
                <Grid item sm={12}>
                  <Controller
                    control={control}
                    name="connectionType"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        fullWidth
                        label={t("Connection Type")}
                        size="small"
                        required
                        error={Boolean(errors.connectionType)}
                        onChange={(e) => handleChangeConnectionType(e, field)}>
                        {connectionTypes.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              )}
              {["My SQL", "SQL", "PostgreSQL", "Snowflake", "Azure SQL", "SAP HANA", undefined].includes(conType) && (
                <>
                  <Grid item sm={12}>
                    <Controller
                      control={control}
                      name="server"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={Boolean(schemaLoading)}
                          label={t("Server")}
                          required
                          fullWidth
                          error={Boolean(errors.server)}
                          variant="outlined"
                          size="small"
                          onChange={(e) => handleChangeInput(e, field)}
                        />
                      )}
                    />
                  </Grid>
                  {conType !== "SAP HANA" && (
                    <Controller
                      control={control}
                      name="dataBase"
                      // defaultValue={connectionData?.dataBase || ""}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          // disabled={Boolean(connectionData?.dataBase)}
                          disabled={Boolean(schemaLoading)}
                          label={t("Database")}
                          required
                          error={Boolean(errors.dataBase)}
                          variant="outlined"
                          size="small"
                          onChange={(e) => handleChangeInput(e, field)}
                        />
                      )}
                    />
                  )}
                </>
              )}
              {["GCP BigQuery"].includes(conType) && (
                <BigQueryForm register={register} control={control} errors={errors} />
              )}
              {["MongoDB"].includes(conType) && <MongoDB register={register} errors={errors} />}
              {["AWS S3"].includes(conType) && <AwsS3 register={register} errors={errors} />}
              {["Azure Blob"].includes(conType) && <AzureBlob register={register} errors={errors} />}
              {["DropBox"].includes(conType) && <DropBox register={register} errors={errors} />}
              {["oneDrive"].includes(conType) && <OneDrive register={register} errors={errors} />}
              {["Oracle"].includes(conType) && (
                <OracleForm
                  register={register}
                  errors={errors}
                  control={control}
                  connectionData={connectionData}
                  handleChangeInput={handleChangeInput}
                />
              )}
              {["Apache HBASE"].includes(conType) && (
                <HBaseForm register={register} control={control} errors={errors} />
              )}
              {["Web App"].includes(conType) && (
                <Generic
                  register={register}
                  errors={errors}
                  control={control}
                  connectionData={connectionData}
                  expresponse={expresponse}
                  setExpresponse={setExpresponse}
                />
              )}
              {["Salesforce"].includes(conType) && (
                <SalesforceForm register={register} errors={errors} control={control} connectionData={connectionData} />
              )}
              {["Databricks"].includes(conType) && (
                <DatabricksForm
                  schemaLoading={schemaLoading}
                  errors={errors}
                  control={control}
                  handleChangeInput={handleChangeInput}
                />
              )}
              {["My SQL", "SQL", "PostgreSQL", "Snowflake", "Azure SQL", "SAP HANA", undefined].includes(conType) && (
                <AuthenticationSection
                  handleChangeInput={handleChangeInput}
                  authTypes={filteredAuthTypes}
                  showPasswordIcon={!connectionData?.password}
                  disabled={Boolean(schemaLoading)}
                  control={control}
                  errors={errors}
                />
              )}
              {["Snowflake", "Databricks", "Oracle", "SQL", "PostgreSQL", "Azure SQL"].includes(conType) && (
                <SelectSchema
                  connectionType={conType}
                  requiredFieldsChanges={requiredFieldsChanges}
                  setRequiredFieldsChanges={setRequiredFieldsChanges}
                  errors={errors}
                  control={control}
                  connectionData={connectionData}
                  getValues={getValues}
                  setValue={setValue}
                  setError={setError}
                  schemaLoading={schemaLoading}
                  setSchemaLoading={setSchemaLoading}
                />
              )}
              <Grid item sm={12}>
                <Controller
                  control={control}
                  name="isPrivate"
                  render={({ field }) => (
                    <CustomSwitchButton labels={[t("public"), t("private")]} checked={!!field.value} {...field} />
                  )}
                />
                <Typography>
                  <strong> {t("Note")} : </strong>
                  {t("note2")}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Box
                  textAlign="right"
                  sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", gap: 2 }}>
                  <Button onClick={handleDrawerClose} variant="outlined" color="error" size="small">
                    {t("Cancel")}
                  </Button>
                  {expresponse && (
                    <CustomLoadingButton
                      disabled={loadingValidate}
                      onClick={handleSubmit(onSubmitValidate)}
                      loading={loadingValidate}>
                      {t("Validate Schema")}
                    </CustomLoadingButton>
                  )}
                  <CustomLoadingButton
                    disabled={loadingSubmit}
                    onClick={handleSubmit(onSubmitTestCreate)}
                    loading={loadingSubmit}>
                    {t("Test")} & {addButton}
                  </CustomLoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default CreateConnection;
