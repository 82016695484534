import { useState } from "react";
import { Grid, Box, IconButton, Typography, Divider, Tabs, Tab } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import SkeletonLoader from "../../SkeletonLoader";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";
import GeneralInfo from "./GeneralInfo";
import InSights from "./InSights";
import SuggestedChecks from "./SuggestedChecks";
const DataProfilePreviewConnection = ({
  previewConData,
  tableList,
  selectedConnection,
  setPreviewConData,
  onClickPrevCon,
  showLoadingPreview,
}) => {
  const [selectedTab, setSelectedTab] = useState("General Info");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleClose = () => {
    setPreviewConData({});
  };

  const onChangeTable = (e, value) => {
    onClickPrevCon(value?.Name);
  };

  const selectedTable = tableList?.find((each) => each.Name === previewConData?.Name);

  return (
    <Dialog className="dataProfilepreviewCon" fullWidth={true} maxWidth="lg" open={true} onClose={handleClose}>
      <DialogTitle className="titleHeader">
        <IconButton onClick={handleClose} size="small" color="error" sx={{ position: "absolute", top: 0, right: 0 }}>
          <CancelOutlinedIcon />
        </IconButton>
        <Grid container>
          <Grid md={3} item>
            <Box sx={{ display: "flex", gap: 1.5, alignItems: "center" }}>
              <img
                src={`/assets/DataInsights/${selectedConnection?.connectionType?.toLowerCase()}.png`}
                width={40}
                alt=""
              />
              <Divider orientation="vertical" flexItem />
              <Box>
                <Typography>Data Source Name : </Typography>
                <Typography color="primary">{selectedConnection?.connectionName}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid md={3} item>
            {previewConData?.Name && (
              <CustomAutoComplete
                sx={{ maxWidth: 220 }}
                disableClearable={true}
                limitTags={1}
                size="small"
                options={tableList}
                placeholder={"Select Table"}
                value={selectedTable}
                label="Select Table"
                getOptionLabel={(opt) => (opt?.Schema ? `${opt?.Schema} . ${opt?.Name}` : opt?.Schema)}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={onChangeTable}
              />
            )}
          </Grid>
          <Grid md={3} item>
            <Typography>No. Of Rows : </Typography>
            <Typography color="primary">{previewConData?.RowsCount}</Typography>
          </Grid>
          <Grid md={3} item>
            <Typography>No. of Columns : </Typography>
            <Typography color="primary">{previewConData?.columns?.length}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ minHeight: "250px" }}>
        {showLoadingPreview ? (
          <SkeletonLoader />
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={selectedTab} onChange={handleChange}>
                <Tab label="General Info" value="General Info" />
                {/* <Tab label="Insights" value="Insights" /> */}
                <Tab label="Suggested Checks" value="Suggested Checks" />
              </Tabs>
            </Box>
            {selectedTab === "General Info" && <GeneralInfo previewConData={previewConData} />}
            {selectedTab === "Insights" && <InSights previewConData={previewConData} />}

            {selectedTab === "Suggested Checks" && (
              <SuggestedChecks previewConData={previewConData} selectedTable={selectedTable} />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DataProfilePreviewConnection;
