import { Box, Typography, Button, Paper } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f7f9fc",
        padding: 2,
      }}>
      <Paper
        elevation={3}
        sx={{
          maxWidth: 480,
          padding: 4,
          textAlign: "center",
          borderRadius: 2,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}>
        <ErrorOutlineIcon sx={{ fontSize: 64, color: "error.main", mb: 2 }} />
        <Typography variant="h4" component="h2" gutterBottom color="text.primary">
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          {error.message || "An unexpected error has occurred."}
        </Typography>
        <Button variant="contained" color="primary" onClick={resetErrorBoundary} sx={{ mt: 2 }}>
          Try Again
        </Button>
      </Paper>
    </Box>
  );
}

export default ErrorFallback;
