import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { customComparator, customNumberComparator } from "../AgGrid/CustomSort";
import { formatDate } from "../../_helpers/utils";

function AgGridTitle(props) {
  return (
    <Box className="v-center">
      <Typography sx={{ color: "#0e71b7", cursor: "pointer" }}>{props.data.connectionName}</Typography>

      {props.data?.UsedInTests?.length > 0 && (
        <Tooltip
          arrow
          title={
            <Box sx={{ overflowY: "auto", maxHeight: 200 }}>
              <Typography sx={{ fontSize: "10px" }}>Used In These Rules : </Typography>
              <Box className="flex column" gap={1}>
                {props.data?.UsedInTests?.map((each, i) => (
                  <Box key={i} className="flex" sx={{ gap: 0.5 }}>
                    {i + 1}
                    <Box>
                      <Typography sx={{ fontSize: "10px" }}>Rule Name : {each.TestName}</Typography>
                      <Typography sx={{ fontSize: "10px" }}>User Email : {each.UserEmail}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          }>
          <IconButton size="small">
            <InfoOutlinedIcon sx={{ fontSize: "16px !important" }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

export const headCells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: function (params) {
      return params.data.UsedInTests.length === 0;
    },
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  },
  {
    field: "connectionName",
    headerName: "Data Source Name",
    cellRenderer: AgGridTitle,
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    flex: 1,
  },
  {
    field: "size",
    headerName: "Size(KB)",
    sortable: true,
    filter: false,
    cellRenderer: fileSize,
    comparator: customNumberComparator,
  },
  {
    field: "createdDate",
    headerName: "Uploaded date",
    sortable: true,
    filter: "agDateColumnFilter",
    cellRenderer: createdDate,
  },
  {
    field: "UserEmail",
    headerName: "Created By",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    flex: 1,
  },
  {},
];

function createdDate(props) {
  return <>{formatDate(props.data.createdDate)}</>;
}

function fileSize(props) {
  return <>{(props.data.size * 0.001).toFixed(1)}</>;
}

export const webAppHeadCells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: function (params) {
      return params.data.UsedInTests.length === 0;
    },
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  },
  {
    field: "connectionName",
    headerName: "Data Source Name",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "APIUrl",
    headerName: "API Url",
    sortable: true,
    filter: false,
  },
  {
    field: "HTTPMethod",
    headerName: "HTTP Method",
    sortable: true,
    filter: false,
  },
  {
    field: "RequestPayload",
    headerName: "Request Pay load",
    sortable: true,
    filter: false,
  },
  {
    field: "authenticationType",
    headerName: "Authentication Type",
    sortable: true,
    filter: false,
  },
  {
    field: "createdDate",
    headerName: "Created date",
    sortable: true,
    cellRenderer: createdDate,
  },
  {},
];
