import {
  Box,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomWidthTooltip from "../../CustomComponents/CustomWidthTooltip";
import OrderbyDropdown from "./OrderbyDropdown";
import DeleteIcon from "@mui/icons-material/Delete";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ViewTableDatabaseToFile from "./ViewTableDatabaseToFile";
import { useContext, useState } from "react";
import { SnackbarContext } from "../../../App";
import APIServices from "../../../services/app.service";
import CustomSwitchButton from "../../CustomComponents/CustomSwitchButton";
import { isAzure } from "../../../_helpers/Constant.js";
const ColumnMapping = ({
  finalValidation,
  source1,
  source2,
  selected,
  dragItem,
  source1Dragitems,
  source2Dragitems,
  source1OrderByColumns,
  source2OrderByColumns,
  selectedSource1Table,
  selectedSource2Table,
  setdragItem,
  setsource1Dragitems,
  setsource2Dragitems,
  setSelected,
  addValidation,
  resetValidation,
}) => {
  const { setSnack } = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const firstSourceOrderBy = searchParams.get("firstSourceOrderBy");
  const secondSourceOrderBy = searchParams.get("secondSourceOrderBy");
  const firstDataSourceType = searchParams.get("firstDataSourceType");
  const secondDataSourceType = searchParams.get("secondDataSourceType");
  const firstSourceConId = searchParams.get("firstSourceConId");
  const firstSourceConType = searchParams.get("firstSourceConType");
  const secondSourceConType = searchParams.get("secondSourceConType");

  const isFullScan = JSON.parse(searchParams.get("isFullScan"));

  const secondSourceConId = searchParams.get("secondSourceConId");

  const [showOrderByError] = useState(false);

  const [source1TableData, setSource1TableData] = useState([]);
  const [source2TableData, setSource2TableData] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const [source1TableDataLoading, setSource1TableDataLoading] = useState(false);
  const [source2TableDataLoading, setSource2TableDataLoading] = useState(false);
  const openFirstDataSource = Boolean(anchorEl);
  const openSecondDataSource = Boolean(anchorEl2);

  const isPrimaryKey = finalValidation?.some(
    (item) =>
      item.source1.some((source1Item) => source1Item?.IsKey) || item.source2.some((source2Item) => source2Item?.IsKey),
  );

  const showSnack = (message, colour = "warning") => {
    setSnack({
      message,
      open: true,
      colour,
    });
  };

  const checkItemExists = (updatedSource, column, sourceType) => {
    const isExistsItem = updatedSource.some((each) => each.Column === column);

    const isExistsInVinalValidations = finalValidation?.some((validation) =>
      validation?.[sourceType]?.some((each) => each.Column === dragItem.Column),
    );
    return isExistsItem || isExistsInVinalValidations;
  };

  const onDrop = () => {
    const updatedSource1 = [...source1Dragitems];
    const updatedSource2 = [...source2Dragitems];

    if (dragItem.source === "source1") {
      if (checkItemExists(updatedSource1, dragItem?.Column, "source1")) {
        showSnack("This Column Already Exists.");
        return;
      }

      if (source2Dragitems.length > updatedSource1.length) {
        const correspondingSource2Item = source2Dragitems[updatedSource1.length];
        const index_ = [];
        updatedSource1.forEach((obj, index) => {
          if (JSON.stringify(obj) === JSON.stringify(dragItem)) {
            if (JSON.stringify(source2Dragitems[index]) === JSON.stringify(correspondingSource2Item)) {
              index_.push(index);
            }
          }
        });
        if (index_.length > 0) {
          showSnack("This Column Mapping Already Exists.");
        } else {
          updatedSource1.push(dragItem);
        }
      } else {
        updatedSource1.push(dragItem);
      }

      setsource1Dragitems(updatedSource1);
    } else if (dragItem.source === "source2") {
      if (checkItemExists(updatedSource2, dragItem?.Column, "source2")) {
        showSnack("This Column Already Exists.");
        return;
      }

      if (source1Dragitems.length > updatedSource2.length) {
        const correspondingSource1Item = source1Dragitems[updatedSource2.length];
        const index_1 = [];
        updatedSource2.forEach((obj, index) => {
          if (JSON.stringify(obj) === JSON.stringify(dragItem)) {
            if (JSON.stringify(source1Dragitems[index]) === JSON.stringify(correspondingSource1Item)) {
              index_1.push(index);
            }
          }
        });
        if (index_1.length > 0) {
          showSnack("This Column Mapping Already Exists.");
        } else {
          updatedSource2.push(dragItem);
        }
      } else {
        updatedSource2.push(dragItem);
      }

      setsource2Dragitems(updatedSource2);
    }

    setdragItem({});
    !isAzure(secondSourceConType) && !isAzure(firstSourceConType) && updateOrderBy();
  };

  const updateOrderBy = () => {
    if (dragItem.source === "source1" && source1Dragitems.length === 0) {
      if (source1Dragitems.length === 0 || !firstSourceOrderBy) {
        !isPrimaryKey &&
          setSearchParams((prev) => {
            const newState = new URLSearchParams(prev);
            newState.set("firstSourceOrderBy", dragItem?.Column);
            return newState;
          });
      } else {
        setSearchParams((prev) => {
          const newState = new URLSearchParams(prev);
          newState.set("firstSourceOrderBy", "");
          return newState;
        });
      }
    } else if (dragItem.source === "source2" && source2Dragitems.length === 0) {
      if (source2Dragitems.length === 0 || !secondSourceOrderBy) {
        !isPrimaryKey &&
          setSearchParams((prev) => {
            const newState = new URLSearchParams(prev);
            newState.set("secondSourceOrderBy", dragItem?.Column);
            return newState;
          });
      } else {
        setSearchParams((prev) => {
          const newState = new URLSearchParams(prev);
          newState.set("secondSourceOrderBy", "");
          return newState;
        });
      }
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = [...Array(range)].map((obj, index) => {
        return index;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleIsKey = (e, index, orderby1, orderby2) => {
    const updatedSource1Dragitems = source1Dragitems.map((each, i) => ({
      ...each,
      IsKey: index === i ? !each.IsKey : false,
    }));

    const updatedSource2Dragitems = source2Dragitems.map((item, i) => ({
      ...item,
      IsKey: index === i ? !item.IsKey : false,
    }));

    setsource1Dragitems(updatedSource1Dragitems);
    setsource2Dragitems(updatedSource2Dragitems);
    if (e.target.checked === true) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("firstSourceOrderBy", orderby1);
        newState.set("secondSourceOrderBy", orderby2);
        return newState;
      });
    }
  };

  const onClickViewTableSource1 = async (e) => {
    e.stopPropagation();
    setSource1TableDataLoading(true);
    setSource1TableData([]);
    setAnchorEl(e.currentTarget);
    const tableName = selectedSource1Table?.table_name;
    const schema = selectedSource1Table?.table_schema;
    const connectionId = firstSourceConId;
    const orderBy = firstSourceOrderBy;

    try {
      const response = await APIServices.getcomparisionViewTableData({
        connectionId: connectionId,
        tableName: tableName,
        orderBy: orderBy,
        schema,
      });

      setSource1TableData(response?.data?.rows);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setSource1TableDataLoading(false);
  };

  const onClickViewTableSource2 = async (e) => {
    e.stopPropagation();
    setSource2TableDataLoading(true);
    setSource2TableData([]);
    setAnchorEl2(e.currentTarget);
    const tableName = selectedSource2Table?.table_name;
    const connectionId = secondSourceConId;
    const orderBy = secondSourceOrderBy;
    const schema = selectedSource2Table?.table_schema;
    try {
      const response = await APIServices.getcomparisionViewTableData({
        connectionId: connectionId,
        tableName: tableName,
        orderBy: orderBy,
        schema,
      });

      setSource2TableData(response?.data?.rows);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setSource2TableDataLoading(false);
  };

  const onChangeFirstSourceOrderBy = (e) => {
    if (e?.value) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("firstSourceOrderBy", e?.value);
        return newState;
      });
    }
  };

  const onChangeSecondSourceOrderBy = (e) => {
    if (e?.value) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("secondSourceOrderBy", e?.value);
        return newState;
      });
    }
  };

  const deleteSelected = () => {
    const filteredSource1 = source1Dragitems.filter((_, index) => !selected.includes(index));
    const filteredSource2 = source2Dragitems.filter((_, index) => !selected.includes(index));

    setsource1Dragitems(filteredSource1);
    setsource2Dragitems(filteredSource2);
    setSelected([]);
  };

  const handleSwitchChange = (e) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isFullScan", e.target.checked);
      return newState;
    });
  };
  const range = source1Dragitems.length > source2Dragitems.length ? source1Dragitems.length : source2Dragitems.length;

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const isCheckedSource1Dragitems = source1Dragitems?.filter((each) => each.IsKey);
  const isCheckedSource2Dragitems = source2Dragitems?.filter((each) => each.IsKey);

  const isCheckedAnyDragitem = isCheckedSource1Dragitems?.length || isCheckedSource2Dragitems?.length;

  const checkValidationSelected =
    (source1Dragitems.length === 0 && source2Dragitems.length === 0) ||
    source1Dragitems?.length !== source2Dragitems.length;
  return (
    <>
      <Grid container sx={{ mt: 2 }}>
        <Box className="space-between" width="100%">
          <Typography variant="sectionHeading">{t("Column Mapping")}</Typography>
          <Box className="v-center">
            <Box sx={{ display: "none" }}>
              <CustomSwitchButton
                labels={[t("Position-Based Row Comparison"), t("Intersection Row Comparison")]}
                checked={isFullScan}
                onChange={handleSwitchChange}
              />

              <CustomWidthTooltip
                arrow
                placement="top-end"
                title={
                  <>
                    <Typography>
                      <b>Position-Based Row Comparison</b> : Conducts a row-by-row comparison of data in datasets or
                      tables by comparing based on the sequential order or positions.
                    </Typography>
                    <Typography>
                      <b>Intersection Row Comparison</b> : Conducts a row-by-row comparison of data in datasets or
                      tables by comparing irrespective of their sequential order or positions.
                    </Typography>
                  </>
                }>
                <IconButton size="small">
                  <InfoOutlinedIcon />
                </IconButton>
              </CustomWidthTooltip>
            </Box>
            {selected.length > 0 && (
              <Box className="v-center">
                {selected.length} selected
                <Tooltip title={t("Delete")}>
                  <IconButton color="error" size="small" onClick={deleteSelected}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Card onDrop={onDrop} onDragOver={(e) => e.preventDefault()}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    "& th.MuiTableCell-root": {
                      backgroundColor: "#e5f6fd",
                      color: "#1976d2",
                      padding: "4px",
                      height: "54px",
                    },
                  }}>
                  <TableCell colSpan={2} align="center" sx={{ width: "50%" }}>
                    <Box className="space-between">
                      <Typography variant="h6">
                        {t("1st Data Source")}
                        {source1?.connectionName && firstDataSourceType === "database" && !isAzure(firstSourceConType)
                          ? " [Database]"
                          : " [File]"}
                      </Typography>

                      {
                        // !source1?.path &&
                        firstDataSourceType === "database" && !isCustomQuery && !isAzure(firstSourceConType) && (
                          <Box className="v-center" gap={1}>
                            <Box>
                              <OrderbyDropdown
                                isDisabled={isCheckedAnyDragitem || isPrimaryKey}
                                options={source1OrderByColumns}
                                fieldName={firstSourceOrderBy}
                                setSelectedValue={onChangeFirstSourceOrderBy}
                                showError={showOrderByError && !firstSourceOrderBy}
                              />
                            </Box>
                            <IconButton
                              sx={{ padding: 0 }}
                              color="primary"
                              aria-describedby={"firstDataSource"}
                              variant="contained"
                              // disabled={firstSourceOrderBy ? false : true}
                              onClick={onClickViewTableSource1}>
                              <Tooltip title={t("View Table Data")} placement="top">
                                <RemoveRedEyeOutlinedIcon size="small" />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        )
                      }
                    </Box>
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    <Box className="space-between">
                      <Typography variant="h6">
                        {t("2nd Data Source")}
                        {source2?.connectionName && secondDataSourceType === "database" && !isAzure(secondSourceConType)
                          ? " [Database]"
                          : " [File]"}
                      </Typography>
                      {
                        // !source2?.path &&
                        secondDataSourceType === "database" && !isCustomQuery && !isAzure(secondSourceConType) && (
                          <Box className="v-center" gap={1}>
                            <Box>
                              <OrderbyDropdown
                                isDisabled={isCheckedAnyDragitem || isPrimaryKey}
                                options={source2OrderByColumns}
                                fieldName={secondSourceOrderBy}
                                setSelectedValue={onChangeSecondSourceOrderBy}
                                showError={showOrderByError && !secondSourceOrderBy}
                              />
                            </Box>
                            <IconButton
                              sx={{ padding: 0 }}
                              color="primary"
                              aria-describedby={"secondDataSource"}
                              variant="contained"
                              // disabled={secondSourceOrderBy ? false : true}
                              onClick={onClickViewTableSource2}>
                              <Tooltip title={t("View Table Data")} placement="top">
                                <RemoveRedEyeOutlinedIcon size="small" />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        )
                      }
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="5%">
                    <Checkbox
                      size="small"
                      onClick={(e) => handleSelectAllClick(e)}
                      checked={selected.length > 0 && selected.length === range}
                    />
                  </TableCell>
                  {source1?.connectionName && !isAzure(firstSourceConType) ? (
                    <>
                      <TableCell width={"41%"}>
                        {t("Table Name")} - {t("Column Name")}
                      </TableCell>
                      {!isCustomQuery &&
                        firstDataSourceType === "database" &&
                        secondDataSourceType === "database" &&
                        source1?.connectionName &&
                        !isAzure(firstSourceConType) &&
                        source1?.connectionName &&
                        !isAzure(secondSourceConType) && (
                          <TableCell sx={{ display: "none" }} align="center" width="8%">
                            {t("Is Key Column ?")}
                          </TableCell>
                        )}
                    </>
                  ) : (
                    <TableCell width="41%"> File </TableCell>
                  )}

                  {source2?.connectionName && !isAzure(secondSourceConType) ? (
                    <TableCell width="46%">
                      {t("Table Name")} - {t("Column Name")}
                    </TableCell>
                  ) : (
                    <TableCell width={"46%"}>File </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(range)].map((obj, index) => {
                  const isItemSelected = isSelected(index);
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox size="small" onClick={(e) => handleClick(e, index)} checked={isItemSelected} />
                      </TableCell>
                      <TableCell>
                        {source1Dragitems[index]?.Table} {source1Dragitems[index]?.Table ? "-" : ""}{" "}
                        {source1Dragitems[index]?.Column}
                      </TableCell>
                      {!isCustomQuery &&
                        firstDataSourceType === "database" &&
                        secondDataSourceType === "database" &&
                        !isAzure(secondSourceConType) &&
                        !isAzure(firstSourceConType) && (
                          <TableCell align="center" sx={{ display: "none" }}>
                            <Radio
                              disabled={isPrimaryKey}
                              checked={source1Dragitems[index]?.IsKey ? true : false}
                              size="small"
                              value={source1Dragitems[index]?.IsKey ? true : false}
                              onClick={(e) => {
                                handleIsKey(e, index, source1Dragitems[index]?.Column, source2Dragitems[index]?.Column);
                              }}
                            />
                          </TableCell>
                        )}

                      <TableCell>
                        {source2Dragitems[index]?.Table} {source2Dragitems[index]?.Table ? "-" : ""}{" "}
                        {source2Dragitems[index]?.Column}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow sx={{ alignItems: "center" }}>
                  <TableCell colSpan={5} align="center">
                    <Typography sx={{ p: 1, fontSize: "14px", opacity: "0.5" }}>{t("Drop here")}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: "right", mt: 2 }}>
          <Button
            variant="outlined"
            color="error"
            disabled={checkValidationSelected || !source1Dragitems.length > 0}
            onClick={() => resetValidation()}
            size="small"
            sx={{ mr: 1 }}>
            {t("Reset")}
          </Button>
          <Button
            className="loader-button"
            variant="contained"
            size="small"
            disabled={checkValidationSelected || !source1Dragitems.length > 0}
            // onClick={() => {
            //   if (
            //     ((secondSourceOrderBy || secondDataSourceType !== "database" || isAzure(secondSourceConType)) &&
            //       (firstSourceOrderBy || firstDataSourceType !== "database" || isAzure(firstSourceConType))) ||
            //     isCustomQuery
            //   ) {
            //     addValidation();
            //   } else {
            //     setShowOrderByError(true);
            //   }
            // }}

            onClick={addValidation}>
            {`${t("Add")} ${t("Data Quality Checks")}`}
          </Button>
        </Grid>
      </Grid>
      {openFirstDataSource && (
        <ViewTableDatabaseToFile
          viewTableData={source1TableData}
          viewTableDataLoading={source1TableDataLoading}
          id={"firstDataSource"}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
      {openSecondDataSource && (
        <ViewTableDatabaseToFile
          viewTableData={source2TableData}
          viewTableDataLoading={source2TableDataLoading}
          id={"secondDataSource"}
          anchorEl={anchorEl2}
          setAnchorEl={setAnchorEl2}
        />
      )}
    </>
  );
};

export default ColumnMapping;
