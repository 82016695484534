import { Grid, TextField } from "@mui/material";
import { FormTabs } from "./FormTabs";
const passwordObj = { tab1: "Password", tab2: "Azure Key vault", labelName: "Password", type: "password" };

function isValidHttpUrl(string) {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (error) {
    return false;
  }
}

export default function HBaseForm({ register, errors, control }) {
  return (
    <>
      <Grid item sm={12}>
        <TextField
          fullWidth
          size="small"
          name="Title"
          label="Description"
          multiline
          rows={4}
          {...register("Description")}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          {...register("host", { required: true })}
          label="Host"
          fullWidth
          error={errors.host ? true : false}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          {...register("httppath", {
            required: true,
            validate: {
              validate: (value) => isValidHttpUrl(value) || "Invalid URL",
            },
          })}
          helperText={errors?.httppath?.message}
          label="Http path"
          fullWidth
          error={errors.httppath ? true : false}
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item sm={12}>
        <TextField
          fullWidth
          size="small"
          label={"User Name"}
          required
          {...register("Username", { required: true })}
          error={Boolean(errors.Username)}
        />
      </Grid>
      <Grid item sm={12}>
        <FormTabs
          label={passwordObj}
          register={register}
          errors={errors}
          control={control}
          // connectionData={connectionData}
        />
      </Grid>
    </>
  );
}
