import { Grid, IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const connectionOptions = [
  { value: "servicename", label: "Oracle Service Name" },
  { value: "sid", label: "Oracle SID" },
];

export default function OracleForm({ errors, control, handleChangeInput }) {
  const [showPassword, setShowPassword] = useState(false);

  const passwordVisibilityIcon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;

  return (
    <>
      <Grid item sm={12}>
        <Controller
          control={control}
          name="server"
          // defaultValue={defaultValues.server || ""}
          rules={{ required: "Host is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required
              fullWidth
              label="Host"
              error={!!errors.server}
              helperText={errors.server?.message}
              variant="outlined"
              size="small"
              onChange={(e) => handleChangeInput(e, field)}
            />
          )}
        />
      </Grid>

      {/* Connection Type and SID/Service Name Fields */}
      <Grid item sm={12} container spacing={2}>
        <Grid item sm={4}>
          <Controller
            control={control}
            name="serviceType"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                select
                label="Service Type"
                size="small"
                onChange={(e) => {
                  handleChangeInput(e, field);
                }}
                error={!!errors.serviceType}
                helperText={errors.serviceType?.message}>
                {connectionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item sm={8}>
          <Controller
            control={control}
            name="sid"
            rules={{ required: "SID is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                fullWidth
                label="SID"
                error={!!errors.sid}
                helperText={errors.sid?.message}
                variant="outlined"
                size="small"
                onChange={(e) => handleChangeInput(e, field)}
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Username Field */}
      <Grid item sm={12}>
        <Controller
          control={control}
          name="user"
          // defaultValue={defaultValues.user || ""}
          rules={{ required: "Username is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              label="User Name"
              required
              error={!!errors.user}
              helperText={errors.user?.message}
              onChange={(e) => handleChangeInput(e, field)}
            />
          )}
        />
      </Grid>

      {/* Password Field */}
      <Grid item sm={12}>
        <Controller
          control={control}
          name="password"
          // defaultValue={defaultValues.password || ""}
          rules={{ required: "Password is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              required
              label="Password"
              type={showPassword ? "text" : "password"}
              error={!!errors.password}
              helperText={errors.password?.message}
              onChange={(e) => handleChangeInput(e, field)}
              InputProps={{
                autoComplete: "off",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                      {passwordVisibilityIcon}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
    </>
  );
}
