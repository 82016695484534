export const headCells = [
  {
    headerName: "S.No",
    valueGetter: (params) => params.node.rowIndex + 1,
    sortable: false,
    filter: false,
    suppressMenu: true,
  },
  {
    field: "Value",
    headerName: "Value",
    sortable: true,
    filter: true,
  },
];
