/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Box, Paper, Tab, Tabs, Typography, Grid } from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import CustomTabs from "../CustomTabs";
import SimpleTable from "../Table/SimpleTable";
import Interactions from "./Interactions";
import Missing from "./Missing";
import PandasOverview from "./PandasOverview";
import PandasHistogram from "./PandasHistogram";
import PandasVariables from "./Variables";

export default function PandasReport({ data }) {
  const TabSections = [
    "Overview",
    "Variables",
    "Interactions",
    "Correlations",
    "Missing Values",
    "Sample",
    "Duplicate rows",
  ];
  const [dataProfileTabs, setDataProfileTabs] = useState(TabSections);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabRefs = useRef(dataProfileTabs.map(() => createRef()));

  const notEmpty = (obj) => {
    return Object.entries(obj).length !== 0;
  };

  useEffect(() => {
    if (notEmpty(data)) {
      let keys = Object.keys(data).filter((key) => {
        if (notEmpty(data[key])) return key;
      });
      let tabs = TabSections.filter((tab) => {
        if (
          keys.includes(tab.toLowerCase()) ||
          (tab === "Overview" && keys.includes("table")) ||
          (tab === "Missing Values" && keys.includes("missing")) ||
          (tab === "Interactions" && keys.includes("scatter")) ||
          (tab === "Duplicate rows" && keys.includes("duplicates"))
        )
          return tab;
      });
      setDataProfileTabs(tabs);
    }
  }, [data]);

  const executeScroll = (index) => {
    tabRefs.current?.[index]?.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };

  return (
    <React.Fragment>
      <Box className="dataProfileHeader">
        <Typography variant="h6" marginBottom="0" gutterBottom component="div">
          DETAILED PROFILING REPORT
        </Typography>
        <Tabs
          value={selectedTab}
          onChange={(e, val) => {
            setSelectedTab(val);
          }}
          TabIndicatorProps={{ style: { background: "#fff" } }}>
          {dataProfileTabs.map((each, index) => (
            <Tab key={index} value={index} label={each} onClick={() => executeScroll(index)} />
          ))}
        </Tabs>
      </Box>
      {notEmpty(data?.table) && (
        <div ref={tabRefs.current[0]}>
          <PandasOverview data={data} />
        </div>
      )}
      {notEmpty(data?.variables) && (
        <div ref={tabRefs.current[1]}>
          <PandasVariables columns={data?.variables} />
        </div>
      )}
      {notEmpty(data?.scatter) && (
        <div ref={tabRefs.current[2]}>
          <Interactions data={data?.scatter} />
        </div>
      )}
      {notEmpty(data?.correlations) && (
        <div ref={tabRefs.current[3]}>
          <PandasHistogram data={data?.correlations} />
        </div>
      )}
      {notEmpty(data?.missing) && (
        <div ref={tabRefs.current[4]}>
          <Missing data={data?.missing} />
        </div>
      )}
      {notEmpty(data?.sample) && (
        <div ref={tabRefs.current[5]}>
          <Paper sx={{ py: 1, px: 2, mt: 2 }} className="sampleContainer">
            <Typography variant="h5" color="primary" mb={1}>
              Sample
            </Typography>
            <CustomTabs
              tabList={["First Rows", "Last Rows"]}
              componentList={{
                "First Rows": <SimpleTable rows={data?.sample[0].data} />,
                "Last Rows": <SimpleTable rows={data?.sample[1].data} />,
              }}
            />
          </Paper>
        </div>
      )}
      {notEmpty(data?.duplicates) && (
        <div ref={tabRefs.current[6]}>
          <Paper sx={{ py: 1, px: 2, mt: 2 }} className="sampleContainer">
            <Grid container item>
              <Grid xs={12} sx={{ p: 0, display: "grid" }} md={12} item>
                <Typography variant="h5" color="primary" mb={1}>
                  Duplicates
                </Typography>
              </Grid>
              <Grid xs={12} sx={{ p: 0, display: "grid" }} md={12} item>
                <SimpleTable rows={Array.isArray(data?.duplicates) ? data?.duplicates : []} />
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </React.Fragment>
  );
}
