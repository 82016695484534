import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";

export default function SimpleTable({ rows }) {
  const [keys] = useState(rows ? Object.keys(rows?.[0] || {}) : []);

  return (
    <TableContainer className="dataproffileTable">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {keys.map((key, index) => {
              return index > 0 ? (
                <TableCell align="right" key={index}>
                  {key}
                </TableCell>
              ) : (
                <TableCell scope="row" key={index}>
                  {key}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={index}>
              {keys.map((key, indx) => {
                return indx > 0 ? (
                  <TableCell align="right" key={indx}>
                    {row?.[key]}
                  </TableCell>
                ) : (
                  <TableCell scope="row" key={indx}>
                    {row?.[key]}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
