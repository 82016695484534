import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const DropBox = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <Grid item sm={12}>
      <TextField
        fullWidth
        size="small"
        name="accessToken"
        label={t("Access Token")}
        minRows={2}
        maxRows={4}
        multiline
        error={!!errors.accessToken}
        helperText={errors.accessToken ? errors.accessToken.message : null}
        {...register("accessToken", { required: true })}
      />
    </Grid>
  );
};

export default DropBox;
