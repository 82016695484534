import { useContext, useRef, useState } from "react";
import { Box, Grid, Table, TableContainer, TableHead, Typography, TableCell, IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { blue } from "@mui/material/colors";

import ExpandableTableRow from "../../Tables/ExpandableTableRow";
import ApiService from "../../../services/app.service";
import ComparativeValidationResult from "./ComparativeValidationResult";
import ComparativeResultModel from "./ComparativeResultModel";
import { formatDateWithTime, getTimeDuration } from "../../../_helpers/utils";

import UniqueTable from "../../Tables/UniqueTable";
import DuplicatesInDataSource from "../../Tables/DuplicatesInDataSource";
import MatchedTable from "../../Tables/MatchedTable";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";

const ExecutionDetailItem = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="detailTitle" sx={{ fontSize: "12px !important" }}>
      {t(title)} :{" "}
      <Typography component="span" variant="detailValue">
        {value}
      </Typography>
    </Typography>
  );
};

export default function ComparativeExecutionResult({ selectedExecution, resultValidation }) {
  const scrollRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [selectedDataType, setSelectedDataType] = useState({});
  const [validateColumnName, setValidateColumnName] = useState({});
  const [display, setDisplay] = useState(false);
  const [loadingType, setLoadingType] = useState(null);

  const validationDetailsRowComparison = resultValidation?.ValidationResults?.map((each) =>
    each?.validationschecks?.filter((rowCount) => rowCount.ValidationName === "RowComparison"),
  ).flat();

  const getTableData = async (itemVal, type, row = null) => {
    // const modifiedType = ["MatchedSecondDatasourceCount", "MatchedFirstDatasourceCount"]?.includes(type)
    //   ? "Matched"
    //   : type;
    try {
      setLoadingType({ id: itemVal?._id, type });
      const response = await ApiService.getFailedRecords({
        validationId: itemVal?._id,
        type: type,
        validationName: itemVal?.ValidationName,
        createdAt: itemVal?.createdAt,
        requestId: row?.requestId,
        batchNumber: row?.batchNumber,
      });

      setSelectedDataType({ type: type, name: type });

      setValidateColumnName({
        columnOne: itemVal?.FirstDataSourceSelectedColumns,
        columnTwo: itemVal?.SecondDataSourceSelectedColumns,
      });

      setTableData(response?.data?.results);
      setDisplay(true);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    }
    setLoadingType(null);
  };

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 600);
  };

  const firstDSTypeFile = resultValidation?.validationDetails?.FirstDatasourceType;
  const secondDSTypeFile = resultValidation?.validationDetails?.SecondDatasourceType;

  return (
    <Box sx={{ width: "100%" }} ref={scrollRef}>
      <Grid sx={{ background: "#DCD4FB" }} container>
        <Grid item md={12}>
          <Box className="space-between" sx={{ borderBottom: "1px solid #ccc", p: 1 }}>
            <Typography variant="sectionHeading">{t("Comparative Test Result")}</Typography>
            <Typography variant="detailTitle">
              {t("Test Name")} :{" "}
              <Typography component="span" variant="detailValue">
                {resultValidation?.validationDetails?.TestName}
              </Typography>
            </Typography>
            <Typography variant="detailTitle">
              {t("Test Type")} :{" "}
              <Typography component="span" variant="detailValue">
                {resultValidation?.validationDetails?.TestType}
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6} sx={{ borderRight: "1px solid #ccc", px: 1, py: 0.5 }}>
          <Typography className="dataSouceHead">{t("1st Data Source")}</Typography>
          <Box className="v-center" gap={2}>
            <ExecutionDetailItem title="Type" value={firstDSTypeFile} />
            <ExecutionDetailItem
              title={firstDSTypeFile === "File" ? "File Name" : "Database Name"}
              value={resultValidation?.validationDetails?.FirstDatasourceName}
            />
          </Box>

          <Box className="flex column">
            {selectedExecution?.summary?.FirstDatasourceSql ? (
              <ExecutionDetailItem title={t("Query")} value={selectedExecution?.summary?.FirstDatasourceSql} />
            ) : (
              <>
                <ExecutionDetailItem
                  title={firstDSTypeFile === "File" ? t("File") : t("Table")}
                  value={selectedExecution?.summary?.FirstDataSourceTable}
                />

                <ExecutionDetailItem
                  title={t("Column")}
                  value={selectedExecution?.summary?.FirstDataSourceSelectedColumns}
                />
                {/* <ExecutionDetailItem title={t("Row Count")} value={itemVal?.RowCountResult?.FirstDataSource} /> */}
              </>
            )}
          </Box>
        </Grid>
        <Grid item md={6} px={1} py={0.5}>
          <Typography className="dataSouceHead">{t("2nd Data Source")}</Typography>

          <Box className="v-center" gap={2}>
            <ExecutionDetailItem title="Type" value={secondDSTypeFile} />
            <ExecutionDetailItem
              title={secondDSTypeFile === "File" ? "File Name" : "Database Name"}
              value={resultValidation?.validationDetails?.SecondDatasourceName}
            />
          </Box>
          <Box className="flex column">
            {selectedExecution?.summary?.SecondDatasourceSql ? (
              <ExecutionDetailItem title={t("Query")} value={selectedExecution?.summary?.SecondDatasourceSql} />
            ) : (
              <>
                <ExecutionDetailItem
                  title={secondDSTypeFile === "File" ? t("File") : t("Table")}
                  value={selectedExecution?.summary?.SecondDataSourceTable}
                />

                <ExecutionDetailItem
                  title={t("Column")}
                  value={selectedExecution?.summary?.SecondDataSourceSelectedColumns}
                />
                {/*  <ExecutionDetailItem title={t("Row Count")} value={itemVal?.RowCountResult?.SecondDataSource} /> */}
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {resultValidation?.ValidationResults?.map((item, index) => {
        const RowComparisonResults = item?.validationschecks?.filter(
          (validationData) => validationData.ValidationName === "RowComparison",
        );
        const indexKey = `${index}`;
        return (
          <TableContainer key={indexKey}>
            <Table>
              <TableHead className="comparisionResultsHead">
                <ExpandableTableRow
                  autoScroll={autoScroll}
                  showData={<span>{t("View Results")}</span>}
                  expandComponent={
                    <ComparativeValidationResult
                      resultValidation={resultValidation}
                      RowComparisonResults={RowComparisonResults}
                      validationDetailsRowComparison={validationDetailsRowComparison}
                      item={item}
                      getTableData={getTableData}
                      loadingType={loadingType}
                    />
                  }>
                  <TableCell width={"20%"} align="center">
                    {t("Batch Number")} : {item?.batchNumber} of {item?.totalBatchCount}
                  </TableCell>
                  <TableCell align="center">
                    {t("Start Time")} :{formatDateWithTime(item?.startDateTime)}
                  </TableCell>
                  <TableCell align="center">
                    {t("Duration")} :{getTimeDuration(item?.startDateTime, item?.finishDateTime, t)}
                  </TableCell>
                </ExpandableTableRow>
              </TableHead>
            </Table>
          </TableContainer>
        );
      })}
      {display && !loadingType?.type && (
        <ComparativeResultModel setDisplay={setDisplay}>
          <>
            <Box sx={{ p: "2px 16px", backgroundColor: blue[300] }}>
              <Grid container>
                <Grid item sm={2}></Grid>
                <Grid className="center" item sm={8}>
                  <Typography variant="h6" color="#fff">
                    {t(selectedDataType?.name?.replace(/([A-Z])/g, " $1")?.trim())}
                  </Typography>
                </Grid>
                <Grid item sm={2}>
                  <IconButton
                    onClick={() => setDisplay(false)}
                    size="small"
                    color="error"
                    sx={{ ml: "auto", display: "flex" }}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Table sx={{ minWidth: 750 }}>
              {tableData?.length !== 0 ? (
                <Box sx={{ px: 1.5, pb: 1.5 }}>
                  {selectedDataType?.type === "OnlyInFirstDatasource" && (
                    <UniqueTable highLightColumn={validateColumnName?.columnOne} headCells={tableData} />
                  )}
                  {selectedDataType?.type === "OnlyInSecondDatasource" && (
                    <UniqueTable highLightColumn={validateColumnName?.columnTwo} headCells={tableData} />
                  )}

                  {selectedDataType?.type === "DuplicatesOfFirstDatasource" && (
                    <DuplicatesInDataSource highLightColumn={validateColumnName?.columnOne} headCells={tableData} />
                  )}
                  {selectedDataType?.type === "DuplicatesOfSecondDatasource" && (
                    <DuplicatesInDataSource highLightColumn={validateColumnName?.columnTwo} headCells={tableData} />
                  )}
                  {["MatchedInFirstSource", "MatchedInSecondSource"]?.includes(selectedDataType?.type) && (
                    <MatchedTable
                      type={selectedDataType?.type}
                      headCells={tableData}
                      highLightColumn={validateColumnName}
                    />
                  )}
                </Box>
              ) : (
                <Typography textAlign={"center"} variant="bold">
                  {t("No Records Found")}
                </Typography>
              )}
            </Table>
          </>
        </ComparativeResultModel>
      )}
    </Box>
  );
}
