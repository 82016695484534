import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import JoinInnerOutlinedIcon from "@mui/icons-material/JoinInnerOutlined";

import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import Box from "@mui/material/Box";
import { Grid, IconButton, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { isDuplicates } from "../../_helpers/Constant";

const ExecutionDetailItem = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="detailTitle">
      {t(title)} :{" "}
      <Typography component="span" variant="detailValue">
        {value}
      </Typography>
    </Typography>
  );
};

const ComparisionResultTypeCard = ({ title, value, icon }) => (
  <Grid
    className="comparisionResultType"
    sx={{
      "& .tile.MuiPaper-root": { cursor: "not-allowed !important", "&:hover": { cursor: "not-allowed !important" } },
    }}
    item
    sm
    // onClick={onClick}
  >
    <Paper className="tile">
      {icon}
      <Typography>
        {title} <br />
        <span>{value}</span>
      </Typography>
      {/* {loading && (
        <IconButton aria-label="loading" size="small">
          <CircularProgress size={20} color="primary" />
        </IconButton>
      )} */}
      <IconButton disabled={true} size="small">
        <ChevronRightOutlinedIcon />
      </IconButton>
    </Paper>
  </Grid>
);
export default function ComparativeValidatePreview({ resultValidation }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ bgcolor: "#eee" }} className="comparativeValRes">
      <Grid container className="dataSourceDetails">
        <Grid item md={6} sx={{ borderRight: "1px solid #fff", px: 1, py: 0.5 }}>
          <Typography className="dataSouceHead">{t("1st Data Source")}</Typography>
          <Box className="v-center" gap={2}>
            <ExecutionDetailItem title="Type" value={resultValidation?.[0]?.FirstDatasourceType} />
            <ExecutionDetailItem title="Name" value={resultValidation?.[0]?.FirstDatasourceName} />
          </Box>
        </Grid>

        <Grid item md={6} px={1} py={0.5}>
          <Typography className="dataSouceHead">{t("2nd Data Source")}</Typography>

          <Box className="v-center" gap={2}>
            <ExecutionDetailItem title="Type" value={resultValidation?.[0]?.SecondDatasourceType} />

            <ExecutionDetailItem title="Name" value={resultValidation?.[0]?.SecondDatasourceName} />
          </Box>
        </Grid>
      </Grid>

      {resultValidation?.map((itemVal, index) => {
        const indexKey = index;
        return (
          <Box key={indexKey}>
            <Grid container>
              <Grid item md={6} className="tableDetails">
                <Typography>
                  <b>{t("Table")} : </b>
                  {itemVal.ValidationProperties?.[0]?.FirstDataSourceTable} | <b>{t("Column")} : </b>
                  {itemVal?.FirstDataSourceSelectedColumns?.join(", ")} | <b>{t("Row Count")} : </b>
                  {itemVal?.RowCountResult?.FirstDataSource}
                </Typography>
              </Grid>

              <Grid className="tableDetails" item md={6}>
                <Typography>
                  <b>{t("Table")} : </b>
                  {itemVal.ValidationProperties?.[0]?.SecondDataSourceTable} | <b>{t("Column")} : </b>
                  {itemVal?.SecondDataSourceSelectedColumns?.join(", ")} | <b>{t("Row Count")} : </b>
                  {itemVal?.RowCountResult?.SecondDataSource}
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ p: 1 }}>
              <Grid container>
                <ComparisionResultTypeCard
                  icon={<JoinInnerOutlinedIcon />}
                  title={t("Matched First Datasource Count")}
                  value={itemVal?.RowComparisonResult?.MatchedFirstDatasourceCount}
                />

                <ComparisionResultTypeCard
                  icon={<JoinInnerOutlinedIcon />}
                  title={t("Matched Second Datasource Count")}
                  value={itemVal?.RowComparisonResult?.MatchedSecondDatasourceCount}
                />

                <ComparisionResultTypeCard
                  icon={<ArticleOutlinedIcon />}
                  title={t("Only in 1st Source")}
                  value={itemVal?.RowComparisonResult?.OnlyInFirstDatasourceCount}
                />

                <ComparisionResultTypeCard
                  icon={<ArticleOutlinedIcon />}
                  title={t("Only in 2nd Source")}
                  value={itemVal?.RowComparisonResult?.OnlyInSecondDatasourceCount}
                />

                {isDuplicates && (
                  <>
                    <ComparisionResultTypeCard
                      icon={<ContentCopyOutlinedIcon />}
                      title={t("Duplicate in 1st Source")}
                      value={itemVal?.RowComparisonResult?.DuplicatesOfFirstDatasourceCount}
                    />
                    <ComparisionResultTypeCard
                      icon={<ContentCopyOutlinedIcon />}
                      title={t("Duplicate in 2nd Source")}
                      value={itemVal?.RowComparisonResult?.DuplicatesOfSecondDatasourceCount}
                    />{" "}
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
