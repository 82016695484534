/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import DataProfileDatabase from "./DataProfileDatabase";
import ProfillingReports from "./ProfillingReports";
import PieChartComponent from "../Charts/PieChartComponent";
import SkeletonLoader from "../SkeletonLoader";
import InnerHeader from "../Layout/InnerHeader";
import { useSearchParams } from "react-router-dom";
import APIServices from "../../services/app.service";

const DataProfiling = () => {
  const { t } = useTranslation();
  const scrollRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams] = useSearchParams({ connectionType: "database" });
  const [selectedSource, setSelectedSource] = useState({});
  const [loader, setLoader] = useState(false);
  const connectionType = searchParams.get("connectionType") || "database";

  // tables
  const [tablesList, setTablesList] = useState([]);
  const [tablesLoading, setTablesLoading] = useState(false);
  const [tableDetails, setTableDetails] = useState(null);

  // columns
  const [columns, setColumns] = useState([]);
  const [columnData, setColumnsData] = useState([]);
  const [columnsLoading, setColumnsLoading] = useState(false);

  // profiling response parameters
  const [responseData, setResponseData] = useState({});
  const [showProfiling, setShowProfiling] = useState(false);
  const [showPieChart, setShowPieChart] = useState(false);
  const [profilingResponse, setProfilingResponse] = useState({});

  const [reportType, setReportType] = useState("DQG");
  const [selectedColumn, setSelectedColumn] = useState([]);
  const [compCols, setCompCols] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const [attrError, setAttrError] = useState(false);

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }, 600);
  };

  const reset = () => {
    setSelectedColumn("");
    setCompCols([]);
    setAttributes([]);
    setColumns([]);
  };
  useEffect(() => {
    const fetchData = async () => {
      setColumnsLoading(true);
      setAttributes([]);
      setCompCols([]);
      setColumns([]);

      const isSimpleProfiling = ["DQG", "Pandas"].includes(reportType);
      if (
        selectedSource &&
        connectionType !== "file" &&
        Object.keys(selectedSource)?.length > 0 &&
        !isSimpleProfiling &&
        tableDetails
      ) {
        const data = {
          connectionId: selectedSource.id,
          tableName: [tableDetails?.table_name],
          tableNames: [{ tableName: tableDetails?.table_name, schema: tableDetails?.table_schema }],
        };

        try {
          const response = await ApiService.GetDataProfilerTablesColumns(data);
          if (!["Azure Blob"].includes(selectedSource?.connectionType)) {
            setColumns(response?.data?.Columns?.[tableDetails?.table_name]);
          } else {
            const filedetails = response?.data?.fileDetails;
            const resultRows = response?.data?.result?.rows;
            let columns = [];
            if (filedetails?.firstRowisHeader) columns = resultRows[0];
            else {
              const numColumns = resultRows[0]?.length || 0;
              columns = Array.from({ length: numColumns }, (_, index) => `Column ${index + 1}`);
            }
            setColumns(columns);
          }
        } catch (error) {
          handleApiError(error);
        } finally {
          setColumnsLoading(false);
        }
      } else if (
        selectedSource &&
        Object.keys(selectedSource)?.length > 0 &&
        !isSimpleProfiling &&
        connectionType === "file"
      ) {
        try {
          const response = await ApiService.GetFilesData({ id: selectedSource.id });
          const filedetails = response?.data?.fileDetails;
          const resultRows = response?.data?.result?.rows;
          let columns = [];
          if (filedetails?.firstRowisHeader) columns = resultRows[0];
          else {
            // Generate an array of column headers based on the number of columns
            const numColumns = resultRows[0]?.length || 0;
            columns = Array.from({ length: numColumns }, (_, index) => `Column ${index + 1}`);
          }

          setColumns(columns);
        } catch (error) {
          handleApiError(error);
        } finally {
          setColumnsLoading(false);
        }
      } else {
        setColumnsLoading(false);
      }
    };

    fetchData();
  }, [selectedSource, tableDetails, reportType]);

  useEffect(() => {
    setAttrError(false);
    setShowPieChart(false);
    setShowProfiling(false);
    setProfilingResponse({});
    setResponseData({});
    setMin(0);
    setMax(0);
  }, [connectionType, reportType, selectedSource, tableDetails, attributes, selectedColumn]);

  useEffect(() => {
    setShowPieChart(false);
    setShowProfiling(false);
    setProfilingResponse({});
    setResponseData({});
    setSelectedColumn("");
    setCompCols([]);
    setTableDetails(null);

    const fetchTables = async () => {
      if (selectedSource && Object.keys(selectedSource)?.length > 0) {
        if (connectionType !== "file") {
          try {
            setTablesLoading(true);
            const results = await APIServices.ConnectionDetails(selectedSource?._id || selectedSource?.id);
            setTablesList(results?.data?.tables);
            setTableDetails(null);
          } catch (error) {
            handleApiError(error);
          } finally {
            setTablesLoading(false);
          }
        }
      } else {
        setTablesList([]);
        setColumns([]);
      }
    };

    fetchTables();
  }, [selectedSource]);

  const onClickSubmit = () => {
    setAttrError(false);
    if (
      (connectionType === "file" && selectedSource && !Object?.keys(selectedSource)?.length > 0) ||
      (connectionType === "database" &&
        selectedSource &&
        (!Object?.keys(selectedSource)?.length > 0 || !tableDetails?.table_name)) ||
      (reportType === "LUX" && (!selectedColumn || compCols?.length < 1)) ||
      (["scatterplot", "Piechart", "Barplot"].includes(reportType) && attributes.length < 2) ||
      (reportType === "boxplot" && attributes.length < 1) ||
      (reportType === "Movablebarplot" && attributes.length < 4)
    ) {
      if (reportType === "LUX" && selectedColumn && compCols.length < 2 && compCols.length !== 0) {
        setSnack({
          message: "At least two compare columns must be selected",
          open: true,
          colour: "error",
        });
      } else {
        setAttrError(true);
        setSnack({
          message: "Fill in all details",
          open: true,
          colour: "error",
        });
      }
    } else {
      setColumnsData([]);
      setProfilingResponse();
      setResponseData({});
      getChartData();
    }
  };

  const getChartData = async () => {
    setResponseData({});
    setShowProfiling(false);
    setProfilingResponse();
    setLoader(true);

    const data = {
      ConnectionId: selectedSource?.id,
      Name: connectionType === "file" ? selectedSource.fileName : "",
      Table: connectionType === "file" ? "" : tableDetails?.table_name,
      Type: connectionType === "file" ? "File" : selectedSource.connectionType,
      reportType: reportType,
      Schema: tableDetails?.table_schema,
      //  baseColumn: selectedColumn ? selectedColumn : columns[0],
      compareColumns: compCols,
      column1: selectedColumn || "",
      possibleAttributes:
        reportType === "Movablebarplot" ? [...attributes, min === "" ? 0 : min, max === "" ? 0 : max] : attributes,
    };

    try {
      const response = await ApiService.dataProfiling(data);

      if (reportType === "DQG") {
        setResponseData(response?.data?.ResponseObject);

        if (response?.data?.ResponseObject?.Columns?.length > 0) {
          setColumnsData(response?.data?.ResponseObject?.Columns);
          setShowPieChart(true);
        } else {
          setSnack({
            message: response?.data?.path?.["ProfilingStatus "] || "Table  Data Is Empty",
            open: true,
            colour: "warning",
          });
        }
      } else {
        if (response?.data?.path?.rows?.length === 0) {
          setSnack({
            message: response?.data?.path?.["ProfilingStatus "] || "Table Data Is Empty",
            open: true,
            colour: "warning",
          });
        } else {
          setShowProfiling(true);
          setProfilingResponse(response?.data?.path);
          response?.data?.path?.["ProfilingStatus "] &&
            setSnack({ message: response?.data?.path?.["ProfilingStatus "], open: true, colour: "warning" });
        }
      }

      autoScroll();
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoader(false);
    }
  };

  const loadFiles = (value) => {
    setSelectedSource(value);
  };

  const loadDatabase = (value) => {
    setSelectedSource(value);
  };

  const handleApiError = (error) => {
    setSnack({
      message: error?.response?.data?.message ?? error?.message,
      open: true,
      colour: "error",
    });
  };

  return (
    <>
      <InnerHeader>
        <Typography variant="h6" className="upper-case">
          {t("Data Profiling")}
        </Typography>
      </InnerHeader>

      <Box className="dataProfileContainer  pt74">
        <Grid container>
          <Grid xs={12} item>
            <Box className="dataProfileDatabases">
              <Box className="containerHead">
                <Typography variant="h6">{t("Data Profiling")}</Typography>
              </Box>
              <DataProfileDatabase
                loader={loader}
                heading="Connections"
                loadFiles={loadFiles}
                loadDatabase={loadDatabase}
                onClickSubmit={onClickSubmit}
                tablesList={tablesList}
                tablesLoading={tablesLoading}
                tableDetails={tableDetails}
                setTableDetails={setTableDetails}
                reportType={reportType}
                setReportType={setReportType}
                setSelectedColumn={setSelectedColumn}
                compCols={compCols}
                setCompCols={setCompCols}
                selectedColumn={selectedColumn}
                columns={columns ? columns : []}
                columnsLoading={columnsLoading}
                attributes={attributes}
                setAttributes={setAttributes}
                min={min}
                setMin={setMin}
                max={max}
                setMax={setMax}
                attrError={attrError}
                reset={reset}
              />
            </Box>
          </Grid>

          {loader && <SkeletonLoader />}
          {!loader && showPieChart && (
            <Grid xs={12} item ref={scrollRef}>
              <Paper className="DPGraphContainer">
                <Grid container className="DPGraphHead" spacing={1}>
                  <Grid md item>
                    <Box>
                      <Typography variant="h6">
                        {connectionType === "database" ? t("Connection") : t("File Name")}
                      </Typography>
                      <Typography>
                        {connectionType === "database"
                          ? selectedSource?.connectionName || t("File")
                          : responseData?.DataSource.Name || "NA"}
                      </Typography>
                    </Box>
                  </Grid>
                  {connectionType === "database" && (
                    <>
                      <Grid md item>
                        <Box>
                          <Typography variant="h6">{t("Database")}</Typography>
                          <Typography>{selectedSource?.connectionType || t("File")}</Typography>
                        </Box>
                      </Grid>
                      <Grid md item>
                        <Box>
                          <Typography variant="h6">{t("Table Name")}</Typography>
                          <Typography>{responseData?.DataSource?.Table || "NA"}</Typography>
                        </Box>
                      </Grid>
                    </>
                  )}
                  <Grid md item>
                    <Box>
                      <Typography variant="h6">{t("Column Count")}</Typography>
                      <Typography>{responseData?.DataSource?.ColumnsCount}</Typography>
                    </Box>
                  </Grid>
                  <Grid md item>
                    <Box>
                      <Typography variant="h6">{t("RowsCount")}</Typography>
                      <Typography>{responseData?.DataSource?.RowsCount}</Typography>
                    </Box>
                    <IconButton onClick={() => setShowPieChart(false)} color="error" size="small">
                      <CancelOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>

                <Box className="DPOuptlabel">
                  <Grid container alignItems="center">
                    <Grid md={2} item>
                      <Typography variant="h6">{t("Column Name")}</Typography>
                    </Grid>
                    <Grid md={10} item>
                      <Grid container spacing={0.5} alignItems="center">
                        <Grid md={7} item>
                          <Typography variant="h6">{t("Data Profile")}</Typography>
                        </Grid>
                        <Grid md={5} item>
                          <Box className="DPToolTip">
                            <Typography variant="h6">
                              {t("Duplicates")} | {t("Distinct")} | {t("Null")}
                            </Typography>

                            <Tooltip title={t("tooltip1")} placement="top" arrow>
                              <IconButton size="small">
                                <ErrorOutlineOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <PieChartComponent columnData={columnData} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
      {showProfiling && profilingResponse && (
        <ProfillingReports response={profilingResponse} reportType={reportType} scrollRef={scrollRef} />
      )}
    </>
  );
};

export default DataProfiling;
