import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ServerBaseURLENV } from "../../_helpers/Constant";
import { showToast } from "../reducers/toastSlice";

const getConnectionsList = createAsyncThunk("auts/fetchListOfAuts", async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.post(`${ServerBaseURLENV}/connections/apis`, data);
    return response.data?.autResponseList;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(showToast({ message: "Failed to fetch list of connections", type: "error" }));
    rejectWithValue(errorMessage);
    throw new Error(errorMessage);
  }
});
export const connectionService = { getConnectionsList };
