import { useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CreateConnection from "../CreateDS/CreateConnection";
import { customComparator } from "../../AgGrid/CustomSort";
import { isAzure, userLocalSession } from "../../../_helpers/Constant";

function AgGridTitle(props) {
  const connectionData = props.data;
  const [state, setState] = useState(false);

  const onSuccessCreate = (newData) => {
    const { setRows, setType, setSelected, refreshData } = props.context;
    setRows?.((prevState) => [newData, ...prevState]);
    setType?.("All");
    setSelected([]);
    refreshData();
  };

  const openDrawer = () => setState(true);
  const closeDrawer = () => setState(false);

  return (
    <>
      <Box className="v-center">
        <Typography onClick={openDrawer} sx={{ color: "#0e71b7", cursor: "pointer" }}>
          {props.data.connectionName}
        </Typography>

        {props.data?.UsedInTests?.length > 0 && (
          <Tooltip
            arrow
            title={
              <Box sx={{ overflowY: "auto", maxHeight: 200 }}>
                <Typography sx={{ fontSize: "10px" }}>Used In These Rules : </Typography>
                <Box className="flex column" gap={1}>
                  {props.data?.UsedInTests?.map((each, i) => (
                    <Box key={i} className="flex" sx={{ gap: 0.5 }}>
                      {i + 1}
                      <Box>
                        <Typography sx={{ fontSize: "10px" }}>Rule Name : {each.TestName}</Typography>
                        <Typography sx={{ fontSize: "10px" }}>User Email : {each.UserEmail}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            }>
            <IconButton size="small">
              <InfoOutlinedIcon sx={{ fontSize: "16px !important" }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {state && (
        <CreateConnection
          type={"View"}
          defaultConType={connectionData?.connectionType}
          connectionData={connectionData}
          handleSuccessCreate={onSuccessCreate}
          onClose={closeDrawer}
        />
      )}
    </>
  );
}

const getDSHeadCells = (type) => {
  const serverField = type === "Databricks" ? "serverHostname" : "server";
  const serverHeader = type === "Databricks" ? "Server Host Name" : "Server";
  const headCellsDSDetails = [
    {
      sortable: false,
      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        const userDetails = JSON.parse(localStorage.getItem(userLocalSession));
        return (
          params.data?.UsedInTests?.length === 0 &&
          (params?.data?.UserId === userDetails?.UserId || userDetails?.RoleName === "Admin")
        );
      },
      lockPosition: "left",
      cellClass: "locked-col",
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      filter: false,
      width: 100,
      suppressMenu: true,
    },
    {
      field: "connectionName",
      headerName: "Data Source Name",
      cellRenderer: AgGridTitle,
      sortable: true,
      suppressColumnsToolPanel: true,
      filter: "agTextColumnFilter",
      comparator: customComparator,
      flex: 1,
    },
    {
      field: "connectionType",
      headerName: "Database Type",
      sortable: true,
      filter: "agTextColumnFilter",
      comparator: customComparator,
      flex: 1,
    },
    {
      field: "UserEmail",
      headerName: "Created By",
      sortable: true,
      filter: "agTextColumnFilter",
      comparator: customComparator,
      flex: 1,
    },

    ...(type === "Databricks"
      ? [
          {
            field: "catalogName",
            headerName: "Catalog Name",
            sortable: true,
            filter: "agTextColumnFilter",
            comparator: customComparator,
            flex: 1,
          },
        ]
      : []),

    ...(!isAzure(type)
      ? [
          {
            field: serverField,
            headerName: serverHeader,
            sortable: true,
            filter: "agTextColumnFilter",
            comparator: customComparator,
            flex: 1,
          },
        ]
      : type === "AWS S3"
      ? [
          {
            field: "bucketName",
            headerName: "Bucket Name",
            sortable: true,
            filter: "agTextColumnFilter",
            flex: 1,
          },
        ]
      : type === "Azure Blob"
      ? [
          {
            field: "containerName",
            headerName: "Container Name",
            sortable: true,
            filter: "agTextColumnFilter",
            flex: 1,
          },
        ]
      : []),

    ...(type !== "Oracle"
      ? [
          {
            field: "dataBase",
            headerName: "Database",
            sortable: true,
            filter: "agTextColumnFilter",
            comparator: customComparator,
            flex: 1,
          },
        ]
      : []),
  ];

  return headCellsDSDetails;
};

export { getDSHeadCells };
